import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LabelService } from '../../../services/label.service';
import { CommonService } from '../../../services/common.service';
import { SharedDataService } from '../../../services/shared-data.service';
import { HttpClient } from '@angular/common/http';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { SkeletonLoaderComponent } from '../skeleton-loader/skeleton-loader.component';
import pako from 'pako';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import { MatIconModule } from '@angular/material/icon';
import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';

interface PeriodicElement {
  ContactId: string;
  Date: string;
  Intent: string;
  PrimaryTopic: string;
  Summary: string;
  CallerType: string;
  JourneyMoment: string;
  CustomerSentimentAtStart: string;
  CustomerSentimentAtEnd: string;
  CallResolution: string;
  Topics: string;
  RootCause: string;
}

interface CsvExportResponse {
  URL: string;
  ready_to_download: boolean;
}

@Component({
  selector: 'app-tabular-view',
  standalone: true,
  imports: [
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    MatTooltipModule,
    MatSelectModule,
    FormsModule,
    SkeletonLoaderComponent,
    ToastrModule,
    MatIconModule,
    MatTooltipModule,
    MatSelectModule,
    ClipboardModule
  ],
  templateUrl: './tabular-view.component.html',
  styleUrls: ['./tabular-view.component.scss'],
})
export class TabularViewComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'ContactId',
    'Date',
    'Intent',
    'PrimaryTopic',
    'Summary',
    'CallerType',
    'JourneyMoment',
    'CustomerSentimentAtStart',
    'CustomerSentimentAtEnd',
    'CallResolution',
    'Topics',
    'RootCause',
    'Agent_AgentInteractionDuration',
    'Agent_ConnectedToAgentTimestamp',
    'Agent_Username',
    'Attributes_authenticationStatus',
    'Attributes_tier',
    'InitiationMethod',
    'Queue_Name',
    'CustomerPainPoint',
  ];
  ELEMENT_DATA: PeriodicElement[] = [];

  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);

  apiData: any;
  apiTotalRow: any;
  labels: any = {};

  @Input() data: any;
  @Input() totalRow: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  tableHeaders: any = [
    { name: 'Contact Id', key: 'ContactId' },
    { name: 'Date', key: 'Date' },
    { name: 'Customer Intent', key: 'Intent' },
    { name: 'Primary Topic', key: 'PrimaryTopic' },
    { name: 'Summary', key: 'Summary' },
    { name: 'Caller Type', key: 'CallerType' },
    { name: 'Journey Moment', key: 'JourneyMoment' },
    { name: 'Sentiment At Start', key: 'CustomerSentimentAtStart' },
    { name: 'Sentiment At End', key: 'CustomerSentimentAtEnd' },
    { name: 'Issue Resolution', key: 'CallResolution' },
    { name: 'Topics', key: 'Topics' },
    { name: 'Root Cause', key: 'RootCause' },
    {
      name: 'Agent Interaction Duration',
      key: 'Agent_AgentInteractionDuration',
    },
    {
      name: 'Connected To Agent Timestamp',
      key: 'Agent_ConnectedToAgentTimestamp',
    },
    { name: 'Agent Username', key: 'Agent_Username' },
    {
      name: 'User Authentication Status',
      key: 'Attributes_authenticationStatus',
    },
    { name: 'User Tier', key: 'Attributes_tier' },
    { name: 'Initiation Method', key: 'InitiationMethod' },
    { name: 'Queue Name', key: 'Queue_Name' },
    { name: 'Customer Painpoint', key: 'CustomerPainPoint' },
  ];

  constructor(
    private cdr: ChangeDetectorRef,
    private shareddataservcie: SharedDataService,
    public commonservice: CommonService,
    public labelservice: LabelService,
    private http: HttpClient,
    private toastr: ToastrService,
    private clipboard: Clipboard
  ) { }

  copyToClipboard(value: string) {
    this.clipboard.copy(value);
  }

  getFormattedValue(row: any, item: any): string {
    let value = row[item.key]?.toString() || '-';

    if (item.key === 'CustomerSentimentAtStart' || item.key === 'CustomerSentimentAtEnd') {
      const sentiment = value.trim().toLowerCase();
      value = sentiment === 'positive' ? 'Positive' :
              sentiment === 'negative' ? 'Negative' :
              sentiment === 'neutral' ? 'Neutral' : '-';
    } else if (item.key === 'Date' || item.key === 'Agent_ConnectedToAgentTimestamp') {
      value = this.formatDateString(value);
    } else if (item.key === 'Agent_AgentInteractionDuration') {
      value = this.formatTimestampForAgentDuration(value);
    }

    return value;
  }

  formatDateString(dateString: string): string {
    const date = new Date(dateString);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const formattedHours = String(hours).padStart(2, '0');

    return `${day} ${month} ${year} ${formattedHours}:${minutes}:${seconds} ${period}`;
  }

  ngOnInit() {
    this.labelservice.getJsonData().subscribe((data) => {
      this.labels = data.labels;
    });
    this.commonservice.headers = this.tableHeaders;
    this.commonservice.displayedColumns = this.commonservice.getHeaderNames(
      this.tableHeaders
    );
    this.apiData = this.shareddataservcie.getApiData();
    this.apiTotalRow = this.shareddataservcie.getapiTotalRow();
    this.dataSource.data = this.apiData || [];
    this.commonservice.dataSource = new MatTableDataSource(this.apiData || []);
    this.commonservice.filterdDataSource = new MatTableDataSource(
      this.apiData || []
    );
    this.commonservice.totalSize = this.apiTotalRow;
    this.commonservice.visiblePageBlock = Math.ceil(
      this.commonservice.totalSize / parseInt(this.commonservice.perPageItem)
    );
    this.fetchTableValueAPIData(
      parseInt(this.commonservice.perPageItem),
      this.commonservice.historyPageNumber
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['this.commonservice.rowValue']) {
      this.commonservice.getPageRange();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.commonservice.filterdDataSource.paginator = this.paginator;
    this.commonservice.filterdDataSource.sort = this.sort;
    this.commonservice.tableColumnSearchKey = 'Summary';
  }

  applyTableFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  fetchTableValueAPIData(pageSize: number, pageNumber: number): void {
    this.commonservice
      .fetchTableValues(this.data, pageSize, pageNumber)
      .subscribe(
        (data) => {
          this.commonservice.rowValue = data.total_count;
          this.shareddataservcie.setapiTotalRow(this.commonservice.rowValue);

          this.dataSource.data = this.apiData || [];
          this.commonservice.dataSource = new MatTableDataSource(
            this.apiData || []
          );
          this.commonservice.filterdDataSource = new MatTableDataSource(
            this.apiData || []
          );

          const transformedData = this.transformData(data?.conversations);

          this.commonservice.totalSize = this.commonservice.rowValue;
          this.commonservice.visiblePageBlock = Math.ceil(
            this.commonservice.totalSize /
            parseInt(this.commonservice.perPageItem)
          );
          this.commonservice.dataSource.data = transformedData;
          this.commonservice.filterdDataSource.data = transformedData;

          this.commonservice.filterdDataSource.data = [
            ...this.commonservice.dataSource.data,
          ];
        },
        (error) => {
          console.error('API Error:', error);
        }
      );
  }

  transformData(conversations: any[]): any[] {
    return conversations.map((conversation: any) => {
      const insights = conversation.Insights;
      const contactAttributes = conversation.ContactAttributes;
      return {
        ContactId: conversation.ContactId,
        Date: conversation.ContactTimestamp,
        Intent: insights.Intent,
        PrimaryTopic: insights.PrimaryTopic,
        Summary: insights.Summary,
        CallerType: insights.CallerType,
        JourneyMoment: insights.JourneyMoment,
        CustomerSentimentAtStart: insights.CustomerSentimentAtStart,
        CustomerSentimentAtEnd: insights.CustomerSentimentAtEnd,
        CallResolution: insights.CallResolution,
        Topics: insights.Topics.join('|'),
        RootCause: insights.RootCause,
        Agent_AgentInteractionDuration:
          contactAttributes.Agent_AgentInteractionDuration,
        Agent_ConnectedToAgentTimestamp:
          contactAttributes.Agent_ConnectedToAgentTimestamp,
        Agent_Username: contactAttributes.Agent_Username,
        Attributes_authenticationStatus:
          contactAttributes.Attributes_authenticationStatus,
        Attributes_tier: contactAttributes.Attributes_tier,
        InitiationMethod: contactAttributes.InitiationMethod,
        Queue_Name: contactAttributes.Queue_Name,
        CustomerPainPoint: insights?.CustomerPainPoint,
      };
    });
  }

  exportPlot(): void {
    this.toastr.info('Exporting in progress.', '');
    this.commonservice.exportDataToCSV(this.data).subscribe(
      response => {
        this.commonservice.exportCSVDataResponse = response;
        this.checkExportStatus();
      },
      error => {
        console.error('Export failed', error);
        this.toastr.error('Failed to export data.', 'Error');
      }
    );
  }

  checkExportStatus(): void {
    this.exportCSVFileStatus();
  }  

  exportCSVFileStatus(attempts: number = 0): void {
    const maxAttempts = 120;
    
    if (attempts >= maxAttempts) {
        this.toastr.error('Failed to export data.', 'Error');
        return;
    }

    this.commonservice.exportCSVDataResponseStatus = false;

    this.commonservice.exportDataToCSVFile(this.commonservice.exportCSVDataResponse).subscribe(
        responseStatus => {
            this.commonservice.exportCSVDataResponseStatus = (responseStatus as unknown as CsvExportResponse).ready_to_download;

            if (this.commonservice.exportCSVDataResponseStatus === true) {
                const data = (responseStatus as unknown as CsvExportResponse).URL;
                this.downloadFile(data);
                this.commonservice.exportCSVDataResponseStatus = false;
            } else {
                setTimeout(() => {
                    this.exportCSVFileStatus(attempts + 1);
                }, 1000);
            }
        },
        error => {
            console.error('Export failed', error);
            this.toastr.error('Failed to export data.', 'Error');
        }
    );
}

downloadFile(data: string): void {
  const anchor = document.createElement('a');
  anchor.href = data;
  const timestamp = this.getCurrentTimestamp();
  const filename = `Data_${timestamp}.csv`;
  anchor.download = filename;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  this.toastr.success('File exported.', '');
}

  private getCurrentTimestamp(): string {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  }

  selected: any = 5;
  ngAfterViewInitCalled = false;

  selectPage(event: any) {
    this.commonservice.perPageItem = event;
    this.commonservice.visiblePageBlock = Math.ceil(
      this.commonservice.totalSize / parseInt(this.commonservice.perPageItem)
    );
    this.onPageChange(this.commonservice.historyPageNumber);
  }
  callPaginator() {
    this.commonservice.filterdDataSource.paginator = this.paginator;
    this.commonservice.filterdDataSource.sort = this.sort;
    this.commonservice.tableColumnSearchKey = 'name';
  }
  getHeaderNames(headers: any): string[] {
    return headers.map((header: any) => header.name);
  }

  filterData(dataSource: MatTableDataSource<any>, searchText: any) {
    const columnsToFilter = [
      'ContactId',
      'Date',
      'Intent',
      'PrimaryTopic',
      'Summary',
      'CallerType',
      'JourneyMoment',
      'CustomerSentimentAtStart',
      'CustomerSentimentAtEnd',
      'CallResolution',
      'Topics',
      'RootCause',
      'Agent_AgentInteractionDuration',
      'Agent_ConnectedToAgentTimestamp',
      'Agent_Username',
      'Attributes_authenticationStatus',
      'Attributes_tier',
      'InitiationMethod',
      'Queue_Name',
      'CustomerPainPoint',
    ];
    const array = this.commonservice.dataSource.data;

    return array.filter((obj: any) => {
      return columnsToFilter.some((key) => {
        if (
          obj[key] &&
          typeof obj[key] === 'string' &&
          obj[key].toLowerCase().includes(searchText.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    const filteredData = this.filterData(
      this.commonservice.dataSource,
      filterValue
    );
    this.commonservice.filterdDataSource.data = filteredData;

    if (this.commonservice.filterdDataSource.paginator) {
      this.commonservice.filterdDataSource.paginator.firstPage();
    }
  }
  goToSelectedPage(pageNumber: number): void {
    const pageNumberInt = parseInt(String(pageNumber), 10);
    if (pageNumber >= 1 && pageNumber <= this.commonservice.visiblePageBlock) {
      this.commonservice.historyPageNumber = pageNumber;
      this.paginator.pageIndex = pageNumber - 1;
      this.paginator.page.emit({
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
        length: this.paginator.length,
      });
      if (
        !isNaN(this.commonservice.historyPageNumber) &&
        this.commonservice.historyPageNumber >= 1 &&
        this.commonservice.historyPageNumber <=
        this.paginator.getNumberOfPages()
      ) {
      } else {
      }
    }
  }
  getPaginationRange(): (number | string)[] {
    const range: (number | string)[] = [];
    const visiblePages = 3;
    if (this.commonservice.visiblePageBlock <= 3) {
      for (let i = 1; i <= this.commonservice.visiblePageBlock; i++) {
        range.push(i);
      }
    } else {
      if (this.commonservice.historyPageNumber <= 3) {
        for (let i = 1; i <= visiblePages; i++) {
          range.push(i);
        }
        range.push('...');
        range.push(this.commonservice.visiblePageBlock);
      } else if (
        this.commonservice.historyPageNumber >=
        this.commonservice.visiblePageBlock - 2
      ) {
        range.push('...');
        for (
          let i = this.commonservice.visiblePageBlock - visiblePages + 1;
          i <= this.commonservice.visiblePageBlock;
          i++
        ) {
          range.push(i);
        }
      } else {
        range.push('...');
        for (
          let i = this.commonservice.historyPageNumber - 1;
          i <= this.commonservice.historyPageNumber + 1;
          i++
        ) {
          range.push(i);
        }
        range.push('...');
        range.push(this.commonservice.visiblePageBlock);
      }
    }
    return range;
  }

  onPageInputChange(event: any): void {
    const enteredPage = parseInt(event.target.value, 10);
    if (
      isNaN(enteredPage) ||
      enteredPage < 1 ||
      enteredPage > this.commonservice.visiblePageBlock
    ) {
      this.commonservice.historyPageNumber = null;
    } else {
      this.commonservice.historyPageNumber = enteredPage;
    }
  }

  onPageKeydown(event: any): void {
    const key = event.key;

    if (key === 'Backspace') {
      return;
    }
    const isNumber = /^\d$/.test(key);
    const enteredPage = parseInt(event.target.value + key, 10);

    if (
      !isNumber ||
      isNaN(enteredPage) ||
      enteredPage < 1 ||
      enteredPage > this.commonservice.visiblePageBlock
    ) {
      event.preventDefault();
      if (
        isNaN(enteredPage) ||
        enteredPage < 1 ||
        enteredPage > this.commonservice.visiblePageBlock
      ) {
        this.commonservice.historyPageNumber = null;
      } else {
        this.commonservice.historyPageNumber = enteredPage;
      }
    }
  }

  convertToNumber(value: string | number): number {
    return typeof value === 'string' ? parseInt(value, 10) : value;
  }
  defaultPageChange(pageNumber: number) {
    this.commonservice.visiblePageBlock = 1;
    if (pageNumber >= 1 && pageNumber <= this.commonservice.visiblePageBlock) {
      this.commonservice.historyPageNumber = pageNumber;
      this.paginator.pageIndex = pageNumber - 1;
      this.paginator.pageSize = parseInt(this.commonservice.perPageItem);
      this.paginator.page.emit({
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
        length: this.paginator.length,
      });
      return 0;
    } else {
      this.defaultPageChange(1);
      return 0;
    }
  }
  onPageChange(pageNumber: number) {
    if (pageNumber >= 1 && pageNumber <= this.commonservice.visiblePageBlock) {
      this.commonservice.historyPageNumber = pageNumber;
      this.paginator.pageIndex = pageNumber - 1;
      this.paginator.pageSize = parseInt(this.commonservice.perPageItem);
      this.paginator.length = this.commonservice.totalSize;
      this.paginator.page.emit({
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
        length: this.paginator.length,
      });
      this.fetchTableValueAPIData(
        parseInt(this.commonservice.perPageItem),
        pageNumber
      );
    } else {
      this.onPageChange(1);
    }
  }

  formatTimestampForAgentDuration(value: any): string {
    const seconds = this.parseSeconds(value);
    if (isNaN(seconds)) {
      return '-';
    }
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${this.padZero(minutes)}m:${this.padZero(remainingSeconds)}s`;
  }

  parseSeconds(value: any): number {
    if (typeof value === 'string') {
      value = value.trim();
    }
    const num = typeof value === 'string' ? parseInt(value, 10) : value;
    return isNaN(num) ? NaN : num;
  }

  padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
}
