import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-chat-box',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './chat-box.component.html',
  styleUrl: './chat-box.component.scss'
})
export class ChatBoxComponent {

  prompts = [
    {
      title: 'Prompt Suggestion',
      text: 'Show me the percent of my calls that are general inquiries'
    },
    {
      title: 'Prompt Suggestion',
      text: 'Schedule Change has gone up in frequency'
    },
    {
      title: 'Prompt Suggestion',
      text: 'Let me see a graph of the relationship between topics and reasons'
    }
  ];

  tabName: any = ['Data Navigator', 'Chat', 'Glossary']
}
