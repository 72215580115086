import { Component } from '@angular/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import { HeaderComponent } from '../../shared/header/header.component';
import { NavbarComponent } from '../../shared/navbar/navbar.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'app-full-layout',
  standalone: true,
  imports: [MatSidenavModule, HeaderComponent, NavbarComponent, DashboardComponent, CommonModule, HttpClientModule],
  templateUrl: './full-layout.component.html',
  styleUrl: './full-layout.component.scss'
})
export class FullLayoutComponent {
  collapsed: boolean = true;

  onCollapsedChanged(collapsed: boolean) {
    this.collapsed = collapsed;
  }
}
