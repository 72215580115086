import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Renderer2,
  HostListener,
  Input,
  SimpleChanges,
} from '@angular/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import * as Highcharts from 'highcharts';
import { ChatBoxComponent } from '../chat-box/chat-box.component';
import HighchartsSankey from 'highcharts/modules/sankey';
HighchartsSankey(Highcharts);
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule } from '@angular/forms';
import { SkeletonLoaderComponent } from '../skeleton-loader/skeleton-loader.component';
import { CommonService } from '../../../services/common.service';
import { HttpClientModule } from '@angular/common/http';
import { LabelService } from '../../../services/label.service';
import { FileConverterService } from '../../../services/file-converter.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';
import { TabularViewComponent } from '../tabular-view/tabular-view.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedDataService } from '../../../services/shared-data.service';
import html2canvas from 'html2canvas';
import {
  NgxDaterangepickerBootstrapComponent,
  NgxDaterangepickerBootstrapDirective,
} from 'ngx-daterangepicker-bootstrap';
import dayjs, { Dayjs } from 'dayjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ToastrModule, ToastrService } from 'ngx-toastr';

interface sentimentCountsData {
  from: string;
  to: string;
  weight: number;
}

interface ConversationOverview {
  'Average Duration': string | 'NA';
  'Issue Resolution Rate': number | 'NA';
  'Overall Sentiment': string | 'NA';
  'Primary Topics': number | 'NA';
  'Reason': number | 'NA';
  'Root Cause': number | 'NA';
  'Total Conversations': number | 'NA';
}

interface ChartData {
  type: 'area' | 'line' | 'bar' | 'column' | 'pie' | 'scatter';
  name: string;
  data: number[];
}

interface ChartDataSankey {
  data: any[];
  custom?: {
    customLabels?: Highcharts.SVGElement[];
  };
}

interface ChartDataBarFormat {
  type: 'area' | 'line' | 'bar' | 'column' | 'pie' | 'scatter';
  name: string;
  data: number[];
}

interface SentimentRecord {
  CustomerSentimentAtStart: string;
  CustomerSentimentAtEnd: string;
}

interface Contact {
  ContactId: string;
  Date: string;
  Intent: string;
  PrimaryTopic: string;
  Summary: string;
  CallerType: string;
  JourneyMoment: string;
  CustomerSentimentAtStart: string;
  CustomerSentimentAtEnd: string;
  CallResolution: string;
  Topics: string;
  RootCause: string;
}

@Component({
  selector: 'app-data-explorer',
  standalone: true,
  imports: [
    NgxDaterangepickerBootstrapDirective,
    MatTooltipModule,
    NgxDaterangepickerBootstrapComponent,
    CommonModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatCheckboxModule,
    MatMenuModule,
    MatIconModule,
    HighchartsChartModule,
    ChatBoxComponent,
    NgMultiSelectDropDownModule,
    FormsModule,
    SkeletonLoaderComponent,
    HttpClientModule,
    MatSlideToggleModule,
    TabularViewComponent,
    ToastrModule,
  ],
  providers: [CommonService, LabelService, FileConverterService],
  templateUrl: './data-explorer.component.html',
  styleUrl: './data-explorer.component.scss',
})
export class DataExplorerComponent {
  dateInput: string = '';
  sevenDaysBefore: string = '';
  dateRange: string[] = [];

  overviewSectionTitle: string | undefined = 'Conversation Overview';
  totalConversations: number = 1400;
  primaryTopics: number = 13;
  reason: number = 22;
  rootCause: number = 36;
  overallSentimate: string | undefined = 'Positive';
  issueResolutionRate: number = 79.1;
  averageDuration: string = '02:33';

  labels: any = {};
  chartJSONDataVar: any;
  sentiments = ['Positive', 'Neutral', 'Negative'];
  filteredSentiments = [...this.sentiments];
  selectedSentiments: string[] = [];
  allSelected = false;
  percentageChartDates: any;
  myFilteredData: { [key: string]: string[] } = {};

  tableViewFlag: boolean = false;

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'filterId',
    textField: 'filterText',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  sentimentCounts: sentimentCountsData[] = [];

  percentageChartFormattedData: ChartData[] = [];
  rootcauseFrequencyBarchartData: ChartDataBarFormat[] = [];

  primaryTopicPercentageChartData_first: ChartData[] = [];
  customerIntentPercentageChartData_first: ChartData[] = [];
  primaryTopicSankeyChartData_first: ChartData[] = [];
  sentimentSankeyChartData_first: ChartDataSankey[] = [];
  primaryTopicFrequencyBarChartData_first: ChartData[] = [];
  resolutionBarChartData_first: ChartData[] = [];

  conversationOverview: any;
  result: any;
  Highcharts: typeof Highcharts = Highcharts;
  filterListPrimaryTopics: any;
  filterListResolution: any;
  filterListCaller: any;
  filterSentiment: any;
  filterCustomerSentimentAtStart: any;
  filterCustomerSentimentAtEnd: any;
  filterJourney: any;
  filterCustomerIntent: any;
  filterRootCause: any;
  filterAgent_Username: any;
  filterAttributes_authenticationStatus: any;
  filterAttributes_tier: any;
  filterInitiationMethod: any;
  filterQueue_Name: any;
  filterCustomerPainPoint: any;
  filterAgent_AgentInteractionDuration: any;
  openFilterOptions = false;
  hoveredGroupIndex = -1;
  filterGroups: any = [];
  initialFilterState: any[] = [];
  filterChanges: boolean = false;

  primaryTopicBarChartVisible: boolean = true;
  resolutionBarChartVisible: boolean = true;
  percentageAreaPrimaryTopicChartVisible: boolean = true;
  percentageAreaCustomerIntentChartVisible: boolean = true;
  sankeyDiagram1Visible: boolean = true;
  sankeyDiagram2Visible: boolean = true;
  uniqueDatesArray: string[] = [];

  selectedDuration: any[] = [{ d_id: 4, name: 'Last 7 Days' }];
  durations: any[] = [
    { d_id: 4, name: 'Last 7 Days' },
    { d_id: 0, name: 'This Month' },
    { d_id: 1, name: 'Last Month' },
    { d_id: 2, name: 'Last Three Months' },
    { d_id: 3, name: 'Last Six Months' },
    { d_id: 5, name: 'Custom' },
  ];
  durationDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'd_id',
    textField: 'name',
    itemsShowLimit: 1,
    allowSearchFilter: false,
    showSelectedItemsAtTop: false,
    defaultOpen: false,
    maxHeight: 130,
  };

  fromDate: string = '';
  toDate: string = '';
  lastValidSelection: any = { d_id: 4, name: 'Last 7 Days' };

  isCustomDate: boolean = false;
  customFromDate: string = '';
  customToDate: string = '';
  tableData: any;

  tableViewBody: any;
  activeGroupIndex: number | null = null;
  chipActiveGroupIndex: number | null = null;
  searchQuery: string[] = [];

  // Daterange Picker Start
  dropsDown = 'down';
  dropsUp = 'up';
  opensRight = 'right';
  opensCenter = 'center';
  opensLeft = 'left';
  selectedRangeCalendarTimeRight: any;
  selectedRangeCalendarCenter: any;
  selectedRangeCalendarAutoLeft: any;
  selectedSingleCalendarTimeRight: any;
  selectedSingleCalendarCenter: any;
  selectedSingleCalendarAutoLeft: any;
  selectedSimpleCalendarTimeUpRight: any;
  selectedSimpleCalendarUpCenter: any;
  selectedSimpleCalendarAutoUpLeft: any;
  selectedRangeCalendarTimeInline: any;
  maxDate?: Dayjs;
  minDate?: Dayjs;
  invalidDates: Dayjs[] = [];

  referenceDate = dayjs().subtract(1, 'day');
  ranges = {
    'Last 7 days': [
      this.referenceDate.subtract(6, 'days').startOf('day'),
      this.referenceDate.endOf('day'),
    ],
    'This month': [
      this.referenceDate.startOf('month'),
      this.referenceDate.endOf('month'),
    ],
    'Last 3 months': [
      this.referenceDate.subtract(3, 'months').startOf('month'),
      this.referenceDate.subtract(1, 'month').endOf('month'),
    ],
    'Last 6 months': [
      this.referenceDate.subtract(6, 'months').startOf('month'),
      this.referenceDate.subtract(1, 'month').endOf('month'),
    ],
  };
  localeTime = {
    firstDay: 1,
    startDate: dayjs().startOf('day'),
    endDate: dayjs().endOf('day'),
    format: 'YYYY-MM-DD',
    separator: ' to ',
    daysOfWeek: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    applyLabel: 'Apply',
    cancelLabel: 'Cancel',
    fromLabel: 'From',
    toLabel: 'To',
  };
  locale = {
    firstDay: 1,
    startDate: dayjs().startOf('day'),
    endDate: dayjs().endOf('day'),
    format: 'YYYY-MM-DD',
    separator: ' to ',
    daysOfWeek: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    applyLabel: 'Apply',
    cancelLabel: 'Cancel',
    fromLabel: 'From',
    toLabel: 'To',
  };
  tooltips = [
    { date: dayjs(), text: '' },
    { date: dayjs().add(2, 'days'), text: '' },
  ];

  startAPIDate: any;
  endAPIDate: any;
  datepickerFunction() {
    this.selectedRangeCalendarAutoLeft = {
      startDate: this.referenceDate.subtract(6, 'days').startOf('day'),
      endDate: this.referenceDate.endOf('day'),
    };
  }

  isInvalidDate = (m: Dayjs) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  isCustomDates = (date: Dayjs) => {
    return date.month() === 0 || date.month() === 6 ? 'mycustomdate' : false;
  };

  isTooltipDate = (m: Dayjs) => {
    const tooltip = this.tooltips.find((tt) => tt.date.isSame(m, 'day'));
    return tooltip ? tooltip.text : false;
  };

  datesUpdatedRange($event: Object) {
    this.startAPIDate =
      this.selectedRangeCalendarAutoLeft.startDate.format('YYYY-MM-DD');
    this.endAPIDate =
      this.selectedRangeCalendarAutoLeft.endDate.format('YYYY-MM-DD');

    this.commonservice.historyPageNumber = 1;
    this.commonservice.loaderFlag = true;
  }

  makeLoaderTrue(event: any) {
    this.commonservice.loaderFlag = true;
    this.cdr.detectChanges();
  }

  getSentimentClass(sentiment: string): string {
    if (sentiment === 'Positive') {
      return 'positive-sentiment';
    } else if (sentiment === 'Negative') {
      return 'negative-sentiment';
    } else if (sentiment === 'Neutral') {
      return 'neutral-sentiment';
    } else {
      return 'default-sentiment';
    }
  }
  // Daterange Picker End

  constructor(
    private cdr: ChangeDetectorRef,
    public shareddataservcie: SharedDataService,
    public commonservice: CommonService,
    public labelservice: LabelService,
    private fileConverterService: FileConverterService,
    private renderer: Renderer2,
    private el: ElementRef,
    private toastr: ToastrService
  ) {
    this.updateDates(this.selectedDuration);
    this.datepickerFunction();
  }

  isSelectButtonEnabled: boolean[] = [];

  ngOnInit(): void {
    this.commonservice.loaderFlag = true;

    setTimeout(() => {
      this.commonservice.loaderFlag = false;
      this.cdr.detectChanges();
    }, 1000);

    this.setDateToToday();
    this.sevenDaysBefore = this.calculateDateSevenDaysBefore(this.dateInput);

    this.labelservice.getJsonData().subscribe((data) => {
      this.labels = data.labels;
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'filterId',
      textField: 'filterText',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.makeFilterBodyClearSet();

    this.loadFetchFunctions();
    this.initializeTempSelections();
    this.isSelectButtonEnabled = this.filterGroups.map(() => false);
  }

  //Data filter functionality start
  initializeTempSelections(): void {
    this.filterGroups.forEach((group: any) => {
      group.filters.tempAllSelected = group.filters.allSelected;
      group.filters?.options?.forEach((option: any) => {
        option.tempIsSelected = option.isSelected;
      });
      this.isSelectButtonEnabled = this.filterGroups.map(() => false);
    });
  }

  toggleFilterOptions(event: MouseEvent): void {
    this.openFilterOptions = !this.openFilterOptions;
    this.activeGroupIndex = null;
    this.chipActiveGroupIndex = null;
    this.activeGroupIndex1 = null;
    event?.stopPropagation();
  }

  toggleGroupOptions(index: number, event: MouseEvent): void {
    this.initializeTempSelections();
    this.activeGroupIndex = this.activeGroupIndex === index ? null : index;
    this.chipActiveGroupIndex = null;
    event?.stopPropagation();
  }

  activeGroupIndex1: number | null = null;
  toggleChipGroupOptions(index: number, event: MouseEvent): void {
    if (this.chipActiveGroupIndex === index) {
      this.revertTempSelections(index);
    }
    this.chipActiveGroupIndex =
      this.chipActiveGroupIndex === index ? null : index;
    this.activeGroupIndex = null;
    this.activeGroupIndex1 = this.activeGroupIndex1 === index ? null : index;
    this.openFilterOptions = false;
    event?.stopPropagation();
  }

  revertTempSelections(index: number): void {
    if (index >= 0 && index < this.filterGroups.length) {
      const filterGroup = this.filterGroups[index];
      filterGroup.filters.tempAllSelected = filterGroup.filters.allSelected;
      filterGroup.filters.options.forEach((option: any) => {
        option.tempIsSelected = option.isSelected;
      });
    }
  }

  applyFilterChanges(dropdownIndex: number): void {
    if (dropdownIndex >= 0 && dropdownIndex < this.filterGroups.length) {
      const filterGroup = this.filterGroups[dropdownIndex];
      filterGroup.filters.allSelected = filterGroup.filters.tempAllSelected;
      filterGroup.filters.options.forEach((option: any) => {
        option.isSelected = option.tempIsSelected;
      });

      this.populateFilteredData();
      this.checkFilterChanges();
      this.chipActiveGroupIndex = null;
    }
    this.activeGroupIndex1 = null;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = this.el.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.openFilterOptions = false;
      this.activeGroupIndex = null;
      this.chipActiveGroupIndex = null;
      this.hoveredGroupIndex = -1;
      this.searchQuery = [];
    }
  }

  stopPropagation(event: MouseEvent) {
    event?.stopPropagation();
  }

  filterDropdown(event: any, dropdownIndex: number): void {
    const searchTerm = event.target.value.toLowerCase();
    if (dropdownIndex >= 0 && dropdownIndex < this.filterGroups.length) {
      this.filterGroups[dropdownIndex]?.filters?.options.forEach(
        (option: any) => {
          option.isFiltered = option?.filterText
            .toLowerCase()
            .includes(searchTerm);
        }
      );
    }
  }

  searchFilter(options: any[], query: string): any[] {
    if (!query || query.trim() === '') {
      return options;
    }
    const filteredOptions = options.filter((option) =>
      option.name.toLowerCase().includes(query.toLowerCase())
    );
    return filteredOptions;
  }

  getSelectedCount(options: any[]): number {
    return options.filter((option) => option.isSelected).length;
  }

  getFirstSelectedOption(options: any[]): string {
    const selectedOption = options.find((option) => option.isSelected);
    return selectedOption ? selectedOption.name : '';
  }

  selectAll(event: any, dropdownIndex: number): void {
    if (dropdownIndex >= 0 && dropdownIndex < this.filterGroups.length) {
      this.filterGroups[dropdownIndex].filters.allSelected = event.checked;
      this.filterGroups[dropdownIndex].filters.options.forEach(
        (option: any) => {
          option.isSelected = event.checked;
        }
      );
      this.populateFilteredData();
    }
    this.checkFilterChanges();
    event?.stopPropagation();
  }

  shouldShowGroup(group: any): boolean {
    return (
      group.filters.allSelected ||
      this.getSelectedCount(group.filters.options) > 0
    );
  }

  toggleSelection(option: any, event: any, dropdownIndex: number): void {
    option.isSelected = event.checked;
    this.updateFilteredSelection(dropdownIndex);
    this.checkFilterChanges();
  }

  updateFilteredSelection(dropdownIndex: number): void {
    this.filterGroups[dropdownIndex].filters.allSelected = true;
    this.filterGroups[dropdownIndex].filters.options.forEach((option: any) => {
      this.filterGroups[dropdownIndex].filters.allSelected =
        option.isSelected &&
        this.filterGroups[dropdownIndex].filters.allSelected;
    });
    this.checkFilterChanges();
  }

  removeOption(dropdownIndex: any, optionIndex: any): void {
    if (dropdownIndex >= 0 && dropdownIndex < this.filterGroups.length) {
      this.filterGroups[dropdownIndex].filters.options[optionIndex].isSelected =
        false;
      this.filterGroups[dropdownIndex].filters.allSelected = false;
      this.populateFilteredData();
    }
    this.checkFilterChanges();
  }

  removeAllSelected(dropdownIndex: any): void {
    if (dropdownIndex >= 0 && dropdownIndex < this.filterGroups.length) {
      this.filterGroups[dropdownIndex].filters.allSelected = false;
      this.filterGroups[dropdownIndex].filters.options.forEach(
        (option: any) => {
          option.isSelected = false;
        }
      );
      this.populateFilteredData();
      this.checkFilterChanges();
    }
  }

  populateFilteredData(): { [key: string]: string[] } {
    const filteredData: { [key: string]: string[] } = {};

    this.filterGroups?.forEach((group: any) => {
      const allOptions = group.filters.options;
      let selectedOptions = allOptions
        ?.filter((option: any) => option.isSelected)
        ?.map((option: any) => option.name);

      if (group.name === 'Agent_AgentInteractionDuration') {
        if (selectedOptions?.length === 0 || selectedOptions?.length === allOptions?.length) {
          filteredData[group?.name] = [];
        } else {
          let durationOptions: string[] = [];
          selectedOptions.forEach((option: string) => {
            switch (option) {
              case 'less than 1 min':
                durationOptions.push('0', '60');
                break;
              case '1-5 mins':
                durationOptions.push('60', '300');
                break;
              case '5-10 mins':
                durationOptions.push('300', '600');
                break;
              case 'more than 10 mins':
                durationOptions.push('600');
                break;
            }
          });
          selectedOptions = durationOptions;
          filteredData[group?.name] = selectedOptions;
        }
      } else {
        if (selectedOptions?.length === 0 || selectedOptions?.length === allOptions?.length) {
          filteredData[group?.name] = [];
        } else {
          filteredData[group?.name] = selectedOptions;
        }
      }
    });

    this.cdr.detectChanges();
    return filteredData;
  }

  @HostListener('document:click', ['$event'])
  onContentClick(event: MouseEvent): void {
    this.openFilterOptions = false;
    this.activeGroupIndex = null;
    this.activeGroupIndex1 = null;
    this.chipActiveGroupIndex = null;
  }
  myFilterListForAPI: { [key: string]: string[] } = {};

  transformFilterKeyData(originalData: any): any {
    const newData: any = {};
    for (const key in originalData) {
      if (originalData.hasOwnProperty(key)) {
        const newKey = key.replace(/\s/g, '');
        newData[newKey] = originalData[key];
      }
    }
    return newData;
  }

  applyFilters(): void {
    const data = this.populateFilteredData();
    this.myFilterListForAPI = data;
    this.commonservice.historyPageNumber = 1;
    this.loadFetchFunctions();
    this.storeInitialFilterState();
    this.checkFilterChanges();
  }

  checkFilterChanges() {
    this.filterChanges = !this.filterGroups.every(
      (group: { filters: { options: any[] } }, groupIndex: number) => {
        return group?.filters?.options?.every((option, optionIndex) => {
          return (
            option.isSelected ===
            this.initialFilterState[groupIndex]?.filters?.options[optionIndex]
              .isSelected
          );
        });
      }
    );
  }

  selectAllTemp(event: any, dropdownIndex: number): void {
    if (dropdownIndex >= 0 && dropdownIndex < this.filterGroups.length) {
      this.filterGroups[dropdownIndex].filters.tempAllSelected = event.checked;
      this.filterGroups[dropdownIndex].filters.options.forEach(
        (option: any) => {
          option.tempIsSelected = event.checked;
        }
      );
      this.checkTempSelectionChanges(dropdownIndex);
    }
    event?.stopPropagation();
  }

  toggleTempSelection(option: any, event: any, dropdownIndex: number): void {
    option.tempIsSelected = event.checked;
    this.updateTempFilteredSelection(dropdownIndex);
    this.checkTempSelectionChanges(dropdownIndex);
  }

  checkTempSelectionChanges(dropdownIndex: number): void {
    if (dropdownIndex >= 0 && dropdownIndex < this.filterGroups.length) {
      const filterGroup = this.filterGroups[dropdownIndex];
      this.isSelectButtonEnabled[dropdownIndex] =
        filterGroup.filters.options.some(
          (option: any) => option.tempIsSelected !== option.isSelected
        );
    }
  }

  updateTempFilteredSelection(dropdownIndex: number): void {
    this.filterGroups[dropdownIndex].filters.tempAllSelected = true;
    this.filterGroups[dropdownIndex].filters.options.forEach((option: any) => {
      this.filterGroups[dropdownIndex].filters.tempAllSelected =
        option.tempIsSelected &&
        this.filterGroups[dropdownIndex].filters.tempAllSelected;
    });
  }

  isContentVisible: boolean = true;
  isImageRotated: boolean = false;

  filterBoxAction(event: Event): void {
    this.isContentVisible = !this.isContentVisible;
    this.isImageRotated = !this.isImageRotated;
  }

  showChartView(): void {
    if (this.tableViewFlag) {
      this.tableViewFlag = false;
    }
  }

  showDataView(): void {
    if (!this.tableViewFlag) {
      this.tableViewFlag = true;
    }
  }

  makeFilterBodyClear() {
    this.myFilterListForAPI = {};
    this.loadFilterList();
    this.intilizeFilter();
    this.loadFetchFunctions();
  }

  makeFilterBodyClearSet() {
    this.myFilterListForAPI = {};
    this.loadFilterList();
    this.intilizeFilter();
  }

  //Data filter functionality end
  
  addQuotesToKeys(obj: any): any {
    const quotedObj: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        quotedObj[`"${key}"`] = obj[key];
      }
    }
    return quotedObj;
  }

  onDurationChange(duration: any[]) {
    if (!duration || duration.length === 0) {
      this.selectedDuration = [this.lastValidSelection];
      this.cdr.detectChanges();
      return;
    }

    if (duration && duration.length > 0 && duration[0].d_id !== undefined) {
      if (duration[0].d_id === 5) {
        this.isCustomDate = true;
      } else {
        this.lastValidSelection = duration[0];
        this.isCustomDate = false;
        this.updateDates(duration[0]);
      }
      this.cdr.detectChanges();
    }
  }

  applyCustomDate(): void {
    if (this.customFromDate && this.customToDate) {
      this.fromDate = this.customFromDate;
      this.toDate = this.customToDate;
      this.lastValidSelection = { d_id: 5, name: 'Custom' };
      this.selectedDuration = [this.lastValidSelection];
      setTimeout(() => {
        this.isCustomDate = false;
      });
      this.cdr.detectChanges();
    }
  }

  cancelCustomDate(): void {
    this.isCustomDate = false;
    this.selectedDuration = [this.lastValidSelection];
  }
  closeDropdown(dropdown: any) {
    setTimeout(() => {
      dropdown.closeDropdown();
    });
  }
  updateDates(duration: any) {
    this.fromDate = this.getFromDate(duration);
    this.toDate = this.getToDate(duration);
  }

  getFromDate(duration: any): string {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    let fromDate: Date;

    switch (duration.d_id) {
      case 4:
        fromDate = new Date(today);
        fromDate.setDate(today.getDate() - 7);
        break;
      case 0:
        fromDate = new Date(today.getFullYear(), today.getMonth(), 1);
        break;
      case 1:
        fromDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        break;
      case 2:
        fromDate = new Date(today.getFullYear(), today.getMonth() - 3, 1);
        break;
      case 3:
        fromDate = new Date(today.getFullYear(), today.getMonth() - 6, 1);
        break;
      default:
        fromDate = new Date();
        break;
    }

    const formattedFromDate = this.formatDate(fromDate);
    return formattedFromDate;
  }

  getToDate(duration: any): string {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    let toDate: Date;

    switch (duration.d_id) {
      case 4:
        toDate = today;
        break;
      case 0:
        toDate = today;
        break;
      case 1:
      case 2:
      case 3:
        toDate = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      default:
        toDate = today;
        break;
    }
    const formattedFromDate = this.formatDate(toDate);
    return formattedFromDate;
  }

  setDateToToday(): void {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    this.dateInput = `${yyyy}-${mm}-${dd}`;
  }

  calculateDateSevenDaysBefore(dateInput: string | number | Date) {
    const inputDate = new Date(dateInput);

    inputDate.setDate(inputDate.getDate() - 6);

    const yyyy = inputDate.getFullYear();
    const mm = String(inputDate.getMonth() + 1).padStart(2, '0');
    const dd = String(inputDate.getDate()).padStart(2, '0');

    this.cdr.detectChanges();
    this.sevenDaysBefore = `${yyyy}-${mm}-${dd}`;
    this.cdr.detectChanges();
    this.dateRange = this.generateDateRange(this.sevenDaysBefore, dateInput);
    this.cdr.detectChanges();

    return `${yyyy}-${mm}-${dd}`;
  }

  formatDate(date: Date): string {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  }

  generateDateRange(
    startDate: string,
    endDate: string | number | Date
  ): string[] {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dateArray = [];

    while (start <= end) {
      dateArray.push(this.formatDate(new Date(start)));
      start.setDate(start.getDate() + 1);
    }

    return dateArray;
  }

  noDataFoundFlag: Boolean = false;

  // Fetch Highcharts Plots APIs
  fetchPrimaryTopicPercentageChartAPIData(): void {
    this.tableViewBody = {
      start_date: this.startAPIDate,
      end_date: this.endAPIDate,
      filters: this.myFilterListForAPI,
    };
    this.tableViewBody = JSON.stringify(this.tableViewBody);

    this.commonservice
      .fetchPrimaryTopicPercentageDataWithDate(this.tableViewBody)
      .subscribe(
        (data) => {
          this.primaryTopicPercentageChartData_first = data.series;

          this.percentageChartDates = data.dates;

          if (this.primaryTopicPercentageChartData_first === undefined) {
            this.noDataFoundFlag = true;
          } else {
            this.noDataFoundFlag = false;
          }
          this.reinitializeOptions();
        },
        (error) => {
          console.error('Error fetching data with date_input', error);
        }
      );
  }

  fetchCustomerIntentPercentageChartAPIData(): void {
    this.tableViewBody = {
      start_date: this.startAPIDate,
      end_date: this.endAPIDate,
      filters: this.myFilterListForAPI,
    };
    this.tableViewBody = JSON.stringify(this.tableViewBody);

    this.commonservice
      .fetchCustomerIntentPercentageDataWithDate(this.tableViewBody)
      .subscribe(
        (data) => {
          this.customerIntentPercentageChartData_first = data.series;
          this.percentageChartDates = data.dates;
          this.reinitializeOptions();
        },
        (error) => {
          console.error('Error fetching data with date_input', error);
        }
      );
  }

  fetchPrimaryTopicFrequencyBarChartAPIData(): void {
    this.tableViewBody = {
      start_date: this.startAPIDate,
      end_date: this.endAPIDate,
      filters: this.myFilterListForAPI,
    };
    this.tableViewBody = JSON.stringify(this.tableViewBody);

    this.commonservice
      .fetchPrimaryTopicFrequencyBarDataWithDate(this.tableViewBody)
      .subscribe(
        (data) => {
          this.primaryTopicFrequencyBarChartData_first = data.series;

          this.reinitializeOptions();
        },
        (error) => {
          console.error('Error fetching data with date_input', error);
        }
      );
  }

  fetchResolutionBarChartAPIData(): void {
    this.tableViewBody = {
      start_date: this.startAPIDate,
      end_date: this.endAPIDate,
      filters: this.myFilterListForAPI,
    };
    this.tableViewBody = JSON.stringify(this.tableViewBody);
    this.commonservice
      .fetchResolutionBarDataWithDate(this.tableViewBody)
      .subscribe(
        (data) => {
          this.resolutionBarChartData_first = data.series;

          this.reinitializeOptions();
        },
        (error) => {
          console.error('Error fetching data with date_input', error);
        }
      );
  }

  primaryTopicSankeyChartData_first_nodes: any;

  fetchPrimaryTopicSankeyChartAPIData(): void {
    this.tableViewBody = {
      start_date: this.startAPIDate,
      end_date: this.endAPIDate,
      filters: this.myFilterListForAPI,
    };
    this.tableViewBody = JSON.stringify(this.tableViewBody);
    this.commonservice
      .fetchPrimaryTopicSankeyDataWithDate(this.tableViewBody)
      .subscribe(
        (data) => {
          this.primaryTopicSankeyChartData_first = data.data.links;
          this.primaryTopicSankeyChartData_first_nodes = data.data.nodes;

          this.reinitializeOptions();
        },
        (error) => {
          console.error('Error fetching data with date_input', error);
        }
      );
  }

  sentiment_nodes: any;
  fetchSentimentSankeyChartAPIData(): void {
    this.tableViewBody = {
      start_date: this.startAPIDate,
      end_date: this.endAPIDate,
      filters: this.myFilterListForAPI,
    };
    this.tableViewBody = JSON.stringify(this.tableViewBody);
    this.commonservice
      .fetchSentimentSankeyDataWithDate(this.tableViewBody)
      .subscribe(
        (data) => {
          this.sentimentSankeyChartData_first = data.data.links;
          this.sentiment_nodes = data.data.nodes;

          this.commonservice.loaderFlag = true;
          setTimeout(() => {
            this.commonservice.loaderFlag = false;
            this.cdr.detectChanges();
          }, 1000);

          this.reinitializeOptions();
        },
        (error) => {
          console.error('Error fetching data with date_input', error);
        }
      );
  }

  filterBody: any;
  fetchDataFiltersAPIData(): void {
    this.filterBody = {
      filters: this.myFilterListForAPI,
    };
    this.filterBody = JSON.stringify(this.filterBody);
    this.commonservice.fetchDataFilters(this.filterBody).subscribe(
      (data) => {
        const filterListArray = data;

        this.filterListPrimaryTopics = data.filters.primarytopic;

        this.filterListResolution = data.filters.callresolution;

        this.filterListCaller = data.filters.callertype;

        this.filterCustomerSentimentAtStart =
          data.filters.customersentimentatstart;

        this.filterCustomerSentimentAtEnd = data.filters.customersentimentatend;

        this.filterJourney = data.filters.journeymoment;

        this.filterCustomerIntent = data.filters.intent;

        this.filterRootCause = data.filters.RootCauseClustered;

        this.filterAgent_Username = data.filters.Agent_Username;

        this.filterAttributes_authenticationStatus =
          data.filters.Attributes_authenticationStatus;

        this.filterAttributes_tier = data.filters.Attributes_tier;

        this.filterInitiationMethod = data.filters.InitiationMethod;

        this.filterQueue_Name = data.filters.Queue_Name;

        this.filterCustomerPainPoint = data.filters.CustomerPainPointClustered;

        this.filterAgent_AgentInteractionDuration =
          data.filters.Agent_AgentInteractionDuration;

        this.intilizeFilter();

        this.reinitializeOptions();
      },
      (error) => {
        console.error('Error fetching data with date_input', error);
      }
    );
  }

  intilizeFilter() {
    this.filterGroups = [
      {
        name: 'PrimaryTopic',
        showName: 'Primary Topic',
        filters: {
          options: this.convertToFilterOptionArray(
            this.filterListPrimaryTopics
          ),
          allSelected: true,
        },
      },
      {
        name: 'Intent',
        showName: 'Customer Intent',
        filters: {
          options: this.convertToFilterOptionArray(this.filterCustomerIntent),
          allSelected: true,
        },
      },
      {
        name: 'CallerType',
        showName: 'Customer Type',
        filters: {
          options: this.convertToFilterOptionArray(this.filterListCaller),
          allSelected: true,
        },
      },
      {
        name: 'JourneyMoment',
        showName: 'Journey Moment',
        filters: {
          options: this.convertToFilterOptionArray(this.filterJourney),
          allSelected: true,
        },
      },
      {
        name: 'CustomerSentimentAtStart',
        showName: 'Sentiment At Start',
        filters: {
          options: this.convertToFilterOptionArray(
            this.filterCustomerSentimentAtStart
          ),
          allSelected: true,
        },
      },
      {
        name: 'CustomerSentimentAtEnd',
        showName: 'Sentiment At End',
        filters: {
          options: this.convertToFilterOptionArray(
            this.filterCustomerSentimentAtEnd
          ),
          allSelected: true,
        },
      },
      {
        name: 'CallResolution',
        showName: 'Issue Resolution',
        filters: {
          options: this.convertToFilterOptionArray(this.filterListResolution),
          allSelected: true,
        },
      },
      {
        name: 'RootCauseClustered',
        showName: 'Root Cause Cluster',
        filters: {
          options: this.convertToFilterOptionArray(this.filterRootCause),
          allSelected: true,
        },
      },
      {
        name: 'Agent_Username',
        showName: 'Agent Username',
        filters: {
          options: this.convertToFilterOptionArray(this.filterAgent_Username),
          allSelected: true,
        },
      },
      {
        name: 'Attributes_authenticationStatus',
        showName: 'User Authentication Status',
        filters: {
          options: this.convertToFilterOptionArray(
            this.filterAttributes_authenticationStatus
          ),
          allSelected: true,
        },
      },
      {
        name: 'Attributes_tier',
        showName: 'User Tier',
        filters: {
          options: this.convertToFilterOptionArray(this.filterAttributes_tier),
          allSelected: true,
        },
      },
      {
        name: 'InitiationMethod',
        showName: 'Initiation Method',
        filters: {
          options: this.convertToFilterOptionArray(this.filterInitiationMethod),
          allSelected: true,
        },
      },
      {
        name: 'Queue_Name',
        showName: 'Queue Name',
        filters: {
          options: this.convertToFilterOptionArray(this.filterQueue_Name),
          allSelected: true,
        },
      },
      {
        name: 'CustomerPainPointClustered',
        showName: 'Customer Painpoint Cluster',
        filters: {
          options: this.convertToFilterOptionArray(
            this.filterCustomerPainPoint
          ),
          allSelected: true,
        },
      },
      {
        name: 'Agent_AgentInteractionDuration',
        showName: 'Agent Interaction Duration',
        filters: {
          options: this.convertToFilterOptionArray(
            this.filterAgent_AgentInteractionDuration
          ),
          allSelected: true,
        },
      },
    ];
    this.storeInitialFilterState();
  }

  storeInitialFilterState() {
    this.initialFilterState = JSON.parse(JSON.stringify(this.filterGroups));
  }

  convertToFilterOptionArray(data: string[]): any[] {
    return data
      ?.filter(item => item && item.trim() !== '') // Remove empty items
      ?.map((item, index) => ({
        filterId: (index + 1).toString(),
        name: item,
        isSelected: true
      }));
  }

  fetchConversationOverviewDetailsAPIData(): void {
    this.tableViewBody = {
      start_date: this.startAPIDate,
      end_date: this.endAPIDate,
      filters: this.myFilterListForAPI,
    };
    this.tableViewBody = JSON.stringify(this.tableViewBody);
    this.commonservice.fetchOverview(this.tableViewBody).subscribe(
      (data) => {
        this.conversationOverview = data;
        this.reinitializeOptions();
      },
      (error) => {
        console.error('API Error:', error);
      }
    );
  }
  // Fetch Highcharts Plot APIs end

  //Handle visibility of plots start
  togglePrimaryTopicBarChart() {
    this.primaryTopicBarChartVisible = true;
    this.hideOtherCharts('primaryTopicBarChart');

    this.commonservice.loaderFlag = true;
    setTimeout(() => {
      this.commonservice.loaderFlag = false;
      this.cdr.detectChanges();
    }, 1000);
  }

  toggleResolutionBarChart() {
    this.resolutionBarChartVisible = true;
    this.hideOtherCharts('resolutionBarChart');

    this.commonservice.loaderFlag = true;
    setTimeout(() => {
      this.commonservice.loaderFlag = false;
      this.cdr.detectChanges();
    }, 1000);
  }

  togglePercentageAreaPrimaryTopicChart() {
    this.percentageAreaPrimaryTopicChartVisible = true;
    this.hideOtherCharts('percentageAreaPrimaryTopicChart');

    this.commonservice.loaderFlag = true;
    setTimeout(() => {
      this.commonservice.loaderFlag = false; //Timer period
      this.cdr.detectChanges();
    }, 1000);
  }

  toggleCutomerIntentPercentageAreaChart() {
    this.percentageAreaCustomerIntentChartVisible = true;
    this.hideOtherCharts('percentageAreaCustomerIntentChart');
    this.commonservice.loaderFlag = true;
    setTimeout(() => {
      this.commonservice.loaderFlag = false;
      this.cdr.detectChanges();
    }, 1000);
  }

  toggleSankeyDiagram1() {
    this.sankeyDiagram1Visible = true;
    this.hideOtherCharts('sankeyDiagram1');
    this.commonservice.loaderFlag = true;
    setTimeout(() => {
      this.commonservice.loaderFlag = false;
      this.cdr.detectChanges();
    }, 1000);
  }

  toggleSankeyDiagram2() {
    this.sankeyDiagram2Visible = true;
    this.hideOtherCharts('sankeyDiagram2');

    this.commonservice.loaderFlag = true;
    setTimeout(() => {
      this.commonservice.loaderFlag = false;
      this.cdr.detectChanges();
    }, 1000);
  }

  hideOtherCharts(selectedChart: string) {
    if (selectedChart !== 'primaryTopicBarChart') {
      this.primaryTopicBarChartVisible = false;
    }
    if (selectedChart !== 'resolutionBarChart') {
      this.resolutionBarChartVisible = false;
    }
    if (selectedChart !== 'percentageAreaPrimaryTopicChart') {
      this.percentageAreaPrimaryTopicChartVisible = false;
    }
    if (selectedChart !== 'percentageAreaCustomerIntentChart') {
      this.percentageAreaCustomerIntentChartVisible = false;
    }
    if (selectedChart !== 'sankeyDiagram1') {
      this.sankeyDiagram1Visible = false;
    }
    if (selectedChart !== 'sankeyDiagram2') {
      this.sankeyDiagram2Visible = false;
    }
  }
  // Handle visibility of plots end

  // Plots initilization
  primaryTopicBarChartOptions: Highcharts.Options = {
    chart: {
      type: 'bar',
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: ['Primary Topic'],
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
    yAxis: {
      title: {
        text: 'Frequency',
      },
    },
    series: [],
  };

  resolutionBarChartOptions: Highcharts.Options = {
    chart: {
      type: 'bar',
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: ['Resolution'],
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
    yAxis: {
      title: {
        text: 'Frequency',
      },
    },
    series: [],
  };

  percentageAreaPrimaryTopicChartOptions: Highcharts.Options = {
    chart: {
      type: 'area',
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [],
      gridLineWidth: 0,
    },
    yAxis: {
      title: {
        text: 'Percentage',
      },
      tickPositions: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      gridLineWidth: 1,
    },
    plotOptions: {
      area: {
        stacking: 'percent',
        lineColor: 'none',
        lineWidth: 1,
        marker: {
          enabled: true,
        },
      },
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      borderWidth: 0,
      itemWidth: 100,
    },
    series: [],
  };

  percentageAreaCustomerIntentChartOptions: Highcharts.Options = {
    chart: {
      type: 'area',
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [],
      gridLineWidth: 0,
    },
    yAxis: {
      title: {
        text: 'Percentage',
      },
      tickPositions: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      gridLineWidth: 1,
    },
    plotOptions: {
      area: {
        stacking: 'percent',
        lineColor: 'none',
        lineWidth: 1,
        marker: {
          enabled: true,
        },
      },
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      borderWidth: 0,
      itemWidth: 100,
    },
    series: [],
  };

  sankeyDiagram1Options: Highcharts.Options = {
    chart: {
      type: 'sankey',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    series: [],
  };

  sankeyDiagram2Options: Highcharts.Options = {
    chart: {
      type: 'sankey',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    plotOptions: {
      sankey: {
        colors: [
          '#f7a35c',
          '#7cb5ec',
          '#90ed7d',
          '#434348',
          '#8085e9',
          '#f15c80',
        ],
      },
    },
    legend: {
      layout: 'vertical',
      align: 'left',
      verticalAlign: 'middle',
    },
    series: [],
  };

  getVisibleChartCount(): number {
    let count = 0;

    if (this.resolutionBarChartVisible) count++;
    if (this.primaryTopicBarChartVisible) count++;
    if (this.percentageAreaPrimaryTopicChartVisible) count++;
    if (this.percentageAreaCustomerIntentChartVisible) count++;
    if (this.sankeyDiagram1Visible) count++;
    if (this.sankeyDiagram2Visible) count++;

    return count;
  }

  @Input() chatboxVisibilityFlag: any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['chatboxVisibilityFlag']) {
    }
  }

  getChartStyle(chartVisible: boolean): string {
    if (!chartVisible) {
      return 'display: none;';
    }

    const height = 'height: 600px;';
    const smallHeight = 'height: 230px !important;';
    const display = 'display: block;';

    const isSingleChart = this.getVisibleChartCount() === 1;
    const chatboxVisible = this.chatboxVisibilityFlag;

    let width;

    if (isSingleChart) {
      width = chatboxVisible ? 'width: 65vw;' : 'width: 84vw;';
    } else {
      width = chatboxVisible ? 'width: 28vw;' : 'width: 38.75vw;';
    }

    return `${width} ${isSingleChart ? height : smallHeight} ${display}`;
  }

  pathStack: string[] = ['Overview'];

  pushToPathStack(item: string) {
    this.pathStack.push(item);
  }

  popFromPathStack() {
    this.primaryTopicBarChartVisible = true;
    this.resolutionBarChartVisible = true;
    this.percentageAreaPrimaryTopicChartVisible = true;
    this.percentageAreaCustomerIntentChartVisible = true;
    this.sankeyDiagram1Visible = true;
    this.sankeyDiagram2Visible = true;

    if (this.pathStack.length > 1) {
      this.pathStack.pop();
    }
  }

  customizedDates: string[] = [];

  transformDates(dates: (string | null)[][]): string[] {
    if (!dates || !Array.isArray(dates)) {
      return [];
    }

    return dates.map((range) => {
      if (!Array.isArray(range) || range.length < 1 || range.length > 2) {
        return '';
      }

      const [start, end] = range;

      // Create date objects in UTC by appending 'Z' to the date strings
      const startDate = start ? new Date(start + 'T00:00:00Z') : null;
      const endDate = end ? new Date(end + 'T00:00:00Z') : null;

      const options: Intl.DateTimeFormatOptions = {
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
      };

      const formattedStart = startDate
        ? new Intl.DateTimeFormat('en-US', options).format(startDate)
        : '';

      if (!endDate || !end) {
        return formattedStart;
      }

      const formattedEnd = endDate
        ? new Intl.DateTimeFormat('en-US', options).format(endDate)
        : '';

      return `${formattedStart} - ${formattedEnd}`;
    });
  }

  //load plots functions
  loadFetchFunctions() {
    this.fetchConversationOverviewDetailsAPIData(),
      this.fetchTableValueAPIData(
        parseInt(this.commonservice.perPageItem),
        this.commonservice.historyPageNumber
      ),
      this.fetchPrimaryTopicPercentageChartAPIData(),
      this.fetchCustomerIntentPercentageChartAPIData(),
      this.fetchPrimaryTopicFrequencyBarChartAPIData(),
      this.fetchResolutionBarChartAPIData(),
      this.fetchPrimaryTopicSankeyChartAPIData(),
      this.fetchSentimentSankeyChartAPIData(),
      (this.commonservice.loaderFlag = false);
    this.cdr.detectChanges();
  }

  fetchTableValueAPIData(pageSize: number, pageNumber: number): void {
    this.commonservice
      .fetchTableValues(this.tableViewBody, pageSize, pageNumber)
      .subscribe(
        (data) => {
          const transformedData = this.transformData(data.conversations);
          this.commonservice.rowValue = data.total_count;
          this.shareddataservcie.setapiTotalRow(this.commonservice.rowValue);

          this.commonservice.totalSize = this.commonservice.rowValue;
          this.commonservice.visiblePageBlock = Math.ceil(
            this.commonservice.totalSize /
            parseInt(this.commonservice.perPageItem)
          );

          this.commonservice.dataSource.data = transformedData;
          this.commonservice.filterdDataSource.data = transformedData;

          this.commonservice.filterdDataSource.data = [
            ...this.commonservice.dataSource.data,
          ];
        },
        (error) => {
          console.error('API Error:', error);
        }
      );
  }

  transformData(conversations: any[]): any[] {
    return conversations.map((conversation) => {
      const insights = conversation.Insights;
      const contactAttributes = conversation.ContactAttributes;
      return {
        ContactId: conversation.ContactId,
        Date: conversation.ContactTimestamp,
        Intent: insights.Intent,
        PrimaryTopic: insights.PrimaryTopic,
        Summary: insights.Summary,
        CallerType: insights.CallerType,
        JourneyMoment: insights.JourneyMoment,
        CustomerSentimentAtStart: insights.CustomerSentimentAtStart,
        CustomerSentimentAtEnd: insights.CustomerSentimentAtEnd,
        CallResolution: insights.CallResolution,
        Topics: insights.Topics.join('|'),
        RootCause: insights.RootCause,
        Agent_AgentInteractionDuration:
          contactAttributes.Agent_AgentInteractionDuration,
        Agent_ConnectedToAgentTimestamp:
          contactAttributes.Agent_ConnectedToAgentTimestamp,
        Agent_Username: contactAttributes.Agent_Username,
        Attributes_authenticationStatus:
          contactAttributes.Attributes_authenticationStatus,
        Attributes_tier: contactAttributes.Attributes_tier,
        InitiationMethod: contactAttributes.InitiationMethod,
        Queue_Name: contactAttributes.Queue_Name,
        CustomerPainPoint: insights?.CustomerPainPoint,
      };
    });
  }

  loadFilterList() {
    this.fetchDataFiltersAPIData();
  }

  nodes: any;
  reinitializeOptions() {
    //Reinitialiation of the Conversation Sentiment Flow (Sankey chart 2)
    this.nodes = [
      {
        id: 'Customer Sentiment At Start: Negative',
        name: ' Negative ',
        color: '#d4d4d4',
      },
      {
        id: 'Customer Sentiment At End: Negative',
        name: ' Negative ',
        color: '#d4d4d4',
      },
      {
        id: 'Customer Sentiment At Start: Positive',
        name: ' Positive ',
        color: '#d4d4d4',
      },
      {
        id: 'Customer Sentiment At End: Positive',
        name: ' Positive ',
        color: '#d4d4d4',
      },
      {
        id: 'Customer Sentiment At Start: Neutral',
        name: ' Neutral ',
        color: '#d4d4d4',
      },
      {
        id: 'Customer Sentiment At End: Neutral',
        name: ' Neutral ',
        color: '#d4d4d4',
      },
    ];

    const labels = ['Column 1', 'Column 2'];

    this.sankeyDiagram2Options = {
      chart: {
        type: 'sankey',
        spacingTop: 20,
        events: {
          render: function () {
            const chart = this as any;
            const renderer = chart.renderer;

            if (chart.customStartLabel) {
              chart.customStartLabel.destroy();
            }
            if (chart.customEndLabel) {
              chart.customEndLabel.destroy();
            }

            // Position and add "Sentiment at Start" label
            chart.customStartLabel = renderer
              .text(
                'Sentiment at Start',
                chart.plotLeft + 50,
                chart.plotTop - 10
              )
              .attr({
                align: 'center',
              })
              .css({
                fontSize: '13px',
                color: '#333',
                fontWeight: '600'
              })
              .add();

            // Position and add "Sentiment at End" label
            chart.customEndLabel = renderer
              .text(
                'Sentiment at End',
                chart.plotLeft + chart.plotWidth - 50,
                chart.plotTop - 10
              )
              .attr({
                align: 'center',
              })
              .css({
                fontSize: '13px',
                color: '#333',
                fontWeight: '600'
              })
              .add();
          },
        },
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          data: this.sentimentSankeyChartData_first,
          nodes: this.nodes,
          type: 'sankey',
          name: '',
          nodeWidth: 3,
          colorByPoint: true,
          events: {
            mouseOver: function (event) {
              const point = event.target as any;
              if (point.isNode) {
                this.chart.tooltip.refresh(point);
              }
            },
          }
        },
      ],
      plotOptions: {
        sankey: {
          borderColor: '#708090',
          borderWidth: 1,
          dataLabels: {
            enabled: true,
            useHTML: true,
            align: 'center',
            verticalAlign: 'middle',
            formatter: function () {
              const point = this.point;
              const name = point.name;
              const maxWidth = point.shapeArgs?.['width'];
              const maxLength = Math.floor(maxWidth / 7);

              if (name.length > maxLength) {
                const lines = [];
                let currentLine = '';
                name.split(' ').forEach(word => {
                  if ((currentLine + word).length <= maxLength) {
                    currentLine += word + ' ';
                  } else {
                    lines.push(currentLine.trim());
                    currentLine = word + ' ';
                  }
                });
                if (currentLine) lines.push(currentLine.trim());

                return "";
              } else {
                return "";
              }
            },
            style: {
              fontSize: '13px',
              fontWeight: '500'
            },
            inside: true
          }
        },
      },
      tooltip: {
        formatter: function () {
          const point = this.point as any;
          const fromName = (point.fromNode as any).name;
          const toName = (point.toNode as any).name;
          const weight = point.weight;

          return `<b>${fromName} ➔ ${toName}</b><br><b>Count:</b> ${weight}`;
        },
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'middle',
      },
    };

    //Reinitialiation of the Primary Topics - Customer Intents (Sankey chart 1)
    this.sankeyDiagram1Options = {
      chart: {
        type: 'sankey',
        spacingTop: 20,
        events: {
          render: function () {
            const chart = this as any;
            const renderer = chart.renderer;
    
            if (chart.customStartLabel) {
              chart.customStartLabel.destroy();
            }
            if (chart.customEndLabel) {
              chart.customEndLabel.destroy();
            }
    
            // Position and add "Sentiment at Start" label
            chart.customStartLabel = renderer.text(
              'Primary Topic',
              chart.plotLeft + 50,
              chart.plotTop - 10
            )
              .attr({
                align: 'center'
              })
              .css({
                fontSize: '13px',
                fontWeight: '600'
              })
              .add();
    
            // Position and add "Sentiment at End" label
            chart.customEndLabel = renderer.text(
              'Customer Intent',
              chart.plotLeft + chart.plotWidth - 50,
              chart.plotTop - 10
            )
              .attr({
                align: 'center'
              })
              .css({
                fontSize: '13px',
                fontWeight: '600'
              })
              .add();
          }
        }
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false
      },
      series: [{
        data: this.primaryTopicSankeyChartData_first,    
        nodes: this.primaryTopicSankeyChartData_first_nodes,
        type: 'sankey',
        name: '',
        nodeWidth: 5,
        colorByPoint: true,
        events: {
          mouseOver: function (event) {
            const point = event.target as any;
            if (point.isNode) {
              this.chart.tooltip.refresh(point);
            }
          },
        }
      }],
      plotOptions: {
        sankey: {
          borderColor: '#708090',
          borderWidth: 1,
          dataLabels: {
            useHTML: true,
            align: 'center',
            verticalAlign: 'middle',
            formatter: function () {
              const point = this.point;
              const name = point.name;
              const maxWidth = point.shapeArgs?.['width'];
              const maxLength = Math.floor(maxWidth / 7);

              if (name.length > maxLength) {
                const lines = [];
                let currentLine = '';
                name.split(' ').forEach(word => {
                  if ((currentLine + word).length <= maxLength) {
                    currentLine += word + ' ';
                  } else {
                    lines.push(currentLine.trim());
                    currentLine = word + ' ';
                  }
                });
                if (currentLine) lines.push(currentLine.trim());

                return "";
              } else {
                return "";
              }
            },
            style: {
              fontSize: '13px',
              fontWeight: '500'
            },
            inside: true,
          }
        }
      },
      tooltip: {
        formatter: function () {
          const point = this.point as any;
          if (point.isNode) {
            return `<b>${point.name}</b><br>Count: ${point.sum}`;
          } else {
            const fromName = (point.fromNode as any).name;
            const toName = (point.toNode as any).name;
            const weight = point.weight;
            return `<b>${fromName} ➔ ${toName}</b><br><b>Count:</b> ${weight}`;
          }
        }
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'middle'
      },
    };
    

    //Reinitialiation of the percentageAreaPrimaryTopicChart (Area chart 1)
    const transformedPercentageDates = this.transformDates(
      this.percentageChartDates
    );

    function generateColorShades(color: any[], count: number) {
      const colors = [];
      const opacityStep = 0.7 / count;

      for (let i = 0; i < count; i++) {
        const opacity = 0.3 + i * opacityStep;
        colors.push(`rgba(${color[0]}, ${color[1]}, ${color[2]}, ${opacity})`);
      }

      return colors;
    }

    const yellow = [255, 215, 0];
    const red = [255, 99, 71];
    const orange = [255, 165, 0];
    const brown = [165, 42, 42];
    const cream = [255, 253, 208];

    const yellowShades = generateColorShades(yellow, 5);
    const redShades = generateColorShades(red, 5);
    const orangeShades = generateColorShades(orange, 5);
    const brownShades = generateColorShades(brown, 5);
    const creamShades = generateColorShades(cream, 5);

    this.percentageAreaPrimaryTopicChartOptions = {
      chart: {
        type: 'area',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: transformedPercentageDates,
        gridLineWidth: 0,
      },
      yAxis: {
        title: {
          text: 'Percentage',
        },
        tickPositions: [0, 20, 40, 60, 80, 100],
        gridLineWidth: 1,
      },
      plotOptions: {
        area: {
          stacking: 'percent',
          lineColor: 'none',
          lineWidth: 1,
          marker: {
            enabled: true,
          },
        },
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        borderWidth: 0,
        useHTML: true,
        itemStyle: {
          whiteSpace: 'nowrap',
          overflow: 'visible',
        },
      },
      tooltip: {
        formatter: function () {
          if (this.y != undefined) {
            return (
              this.x +
              '<br/>' +
              '<b>' +
              this.series.name +
              '</b>' +
              ': ' +
              this.y +
              '%'
            );
          }
          return '';
        },
      },

      series: Array.isArray(this.primaryTopicPercentageChartData_first)
        ? this.primaryTopicPercentageChartData_first.map((data, index) => {
          let colorShades;
          switch (index % 5) {
            case 0:
              colorShades = yellowShades;
              break;
            case 1:
              colorShades = redShades;
              break;
            case 2:
              colorShades = orangeShades;
              break;
            case 3:
              colorShades = brownShades;
              break;
            case 4:
              colorShades = creamShades;
              break;
            default:
              colorShades = yellowShades;
          }

          return {
            type: 'area',
            name: data.name,
            data: data.data,
            color: colorShades[index % 5],
          };
        })
        : [],
    };

    //Reinitialiation of the percentageAreaCustomerIntentChart (Area chart 2)
    this.percentageAreaCustomerIntentChartOptions = {
      chart: {
        type: 'area',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: transformedPercentageDates,
        gridLineWidth: 0,
      },
      yAxis: {
        title: {
          text: 'Percentage',
        },
        tickPositions: [0, 20, 40, 60, 80, 100],
        gridLineWidth: 1,
      },
      plotOptions: {
        area: {
          stacking: 'percent',
          lineColor: 'none',
          lineWidth: 1,
          marker: {
            enabled: true,
          },
        },
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        borderWidth: 0,
        useHTML: true,
        itemStyle: {
          whiteSpace: 'nowrap',
          overflow: 'visible',
        },
      },
      tooltip: {
        formatter: function () {
          if (this.y != undefined) {
            return (
              this.x +
              '<br/>' +
              '<b>' +
              this.series.name +
              '</b>' +
              ': ' +
              this.y +
              '%'
            );
          }
          return '';
        },
      },
      series: this.customerIntentPercentageChartData_first,
    };

    //Reinitialiation of the primaryTopicBarChart (Bar chart 1)
    this.primaryTopicBarChartOptions = {
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: ['Primary Topic'],
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },
      yAxis: {
        title: {
          text: 'Frequency',
        },
      },
      series: this.primaryTopicFrequencyBarChartData_first,
    };

    //Reinitialiation of the resolutionBarChart (Bar chart 2)
    this.resolutionBarChartOptions = {
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: ['Resolution'],
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },
      yAxis: {
        title: {
          text: 'Frequency',
        },
      },
      series: this.resolutionBarChartData_first,
    };
  }

  //Export chart image in JPEG file format
  downloadAsJPEG(divId: string) {
    this.toastr.info('Exporting in progress.', '');
    const originalDiv = document.getElementById(divId);

    if (originalDiv) {
      const rightSection = originalDiv.querySelector(
        '.right-section'
      ) as HTMLElement | null;
      if (rightSection) {
        rightSection.style.display = 'none';
      }

      html2canvas(originalDiv)
        .then((canvas) => {
          const imageData = canvas.toDataURL('image/jpeg');
          const a = this.renderer.createElement('a');
          this.renderer.setAttribute(a, 'href', imageData);
          this.renderer.setAttribute(a, 'download', `${divId}.jpeg`);
          a.click();

          if (rightSection) {
            rightSection.style.display = '';
          }
          this.toastr.success('Chart exported.', '');
        })
        .catch((err) => {
          console.error('Could not generate image', err);

          if (rightSection) {
            rightSection.style.display = '';
          }
        });
    } else {
      console.error('Div not found:', divId);
    }
  }
}
