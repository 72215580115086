import { Injectable } from '@angular/core';
import { Amplify } from 'aws-amplify';
import {
  signUp,
  confirmSignUp,
  signIn,
  signOut,
  fetchUserAttributes,
  fetchAuthSession,
  resetPassword,
  ResetPasswordOutput,
  ConfirmResetPasswordInput,
  fetchDevices,
  forgetDevice,
  confirmResetPassword,
  confirmSignIn
} from 'aws-amplify/auth';
import { Router } from '@angular/router';
import { CookieStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment.dev';
export interface User {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}
@Injectable({
  providedIn: 'root'
})
export class CognitoService {
  private authenticationSubject: BehaviorSubject<any>;
  constructor(private router: Router) {
    Amplify.configure({
    });
    this.authenticationSubject = new BehaviorSubject<boolean>(false);
    cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());
  }

  private accessToken: string | null = null; 

  public signIn(username: any, password: any): Promise<any> {
    return signIn({ username: username, password: password }).then(
      async (data) => {
        
  
        this.getUser().then((userData: any) => {
        }).catch((error: any) => {
          console.error(error);
        });
      }
    ).catch((error) => {
      console.error('Sign in error:', error);
    });
  }

  private getAccessTokenFromCookies(): string | null {
    const cookiePrefix = 'CognitoIdentityServiceProvider';
    const cookieSuffix = 'accessToken';
    const cookies = document.cookie.split(';');
  
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
  
      if (cookie.startsWith(cookiePrefix) && cookie.includes(cookieSuffix)) {
        const cookieValue = cookie.split('=')[1];
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  }

  public getAccessToken(): string | null {
    this.accessToken = this.getAccessTokenFromCookies();
    return this.accessToken;
  }

  public async isAuthenticated() {
    return this.getUser()
      .then((user: any) => {
        if (user) {
          return true;
        } else {
          return false;
        }
      })
      .catch(() => false);
  }

  public signUp(name: string, email: string, password: string): Promise<any> {
    let username = email
    return signUp({
      username: name,
      password: password,
      options: {
        userAttributes: {
          name: name,
          email: email,
        }
      }
    })
  }


  handleSignUpConfirmation(username: string, confirmationCode: string): Promise<any> {
    return confirmSignUp({
      username,
      confirmationCode
    });
  }


  public async reserPassword(username: string) {
    try {
      const output = await resetPassword({ username: username })
      this.handleResetPasswordNextSteps(output);
    } catch (error: any) {
      sessionStorage.clear()
      console.log("Error", error);
    }
  }


  public handleResetPasswordNextSteps(output: ResetPasswordOutput) {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        localStorage.setItem("codeDeliveryDetails", JSON.stringify(codeDeliveryDetails))
        break;
      case 'DONE':
        break;
    }
  }


  public forcePasswordChange(password: string): Promise<any> {
    let data = confirmSignIn({
      challengeResponse: password
    });
    return data
  }


  async handleConfirmResetPassword(
    username: any,
    confirmationCode: any,
    newPassword: any
  ) {
    try {
      await confirmResetPassword({ username, confirmationCode, newPassword });
      sessionStorage.clear()
    } catch (error: any) {
    }
  }


  public async getJwt() {
    const { idToken } =
      (await fetchAuthSession({ forceRefresh: true })).tokens ?? {};
    return idToken?.toString();
  }


  public getUser(): Promise<any> {
    return fetchUserAttributes();
  }


  public getDevice(): Promise<any> {
    return fetchDevices();
  }


  public forgotDevice() {
    try {
      forgetDevice().then(() => {
        this.deleteCookies();
      });
    } catch (error) {
      const cookies = document.cookie
    }
  }


  public deleteCookies() {
    var allCookieArray = document.cookie.split(';');
    for (var i = 0; i < allCookieArray.length; i++) {
      var temp = allCookieArray[i].trim();
      var cookieName = temp.split('=')[0];
      if (cookieName.startsWith("CognitoIdentityServiceProvider")) {
        document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
      }
    }
  }
  clearCookies() {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;";
    }
  }
}