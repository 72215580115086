<header class="header-container">
    <h3>Conversation Insights Dashboard</h3>
    <div class="header-action-container">
        <!-- <div class="export-share-container">
            <button class="btn export-report-btn">
                <img class="btn-icon" src="../../../../assets/icons/export-icon.svg" alt="export-icon">
                <span class="btn-text">Export Report</span>
            </button>
            <button class="btn share-report-btn">
                <img class="btn-icon" src="../../../../assets/icons/share-icon.svg" alt="share-icon">
                <span class="btn-text">Share Report</span>
            </button>
        </div> -->
        <div class="userhelp-container">
            <button class="help-icon">
                <img src="../../../../assets/icons/notification_icon.svg" alt="help-icon">
            </button>
            <button class="user-icon"  (click)="clickLogout()">
                <img src="../../../../assets/icons/user-icon.svg" alt="user-icon">
            </button>
            <!-- <button class="btn" (click)="clickLogout()">Logout</button> -->
        </div>
    </div>
</header>