import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as process from 'process';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private cognitoUrl = `${process.env['COGNITO_HOST_URL']}/oauth2/authorize?response_type=code&client_id=${process.env['COGNITO_CLIENT_ID']}&redirect_uri=${process.env['COGNITO_REDIRECT_URL']}&scope=aws.cognito.signin.user.admin+email+openid`;
  private cognitoClientId = process.env['COGNITO_CLIENT_ID'] || '';
  private domainURL = process.env['COGNITO_REDIRECT_URL'];
  private cognitoRegion = 'ca-central-1';

  constructor(private router: Router, private http: HttpClient) {}

  login() {
    window.location.href = this.cognitoUrl;
  }

  handleAuthCallback() {
    if (window.location.search.indexOf('code=') != -1) {
      let authCode = window.location.search
        .split('?')[1]
        .slice(
          window.location.search.split('?')[1].indexOf('code='),
          window.location.search.split('?')[1].indexOf('&state')
        );
      authCode = window.location.search?.split('?')[1].replace('code=', '');
      this.getURLInfo(true, authCode);
    }
  }

  getURLInfo(isAuthCode: boolean = false, authCode: any = '') {
    if (isAuthCode) {
      try {
        let hostURL = this.cognitoUrl.split('oauth2')[0];
        hostURL = hostURL + 'oauth2/token';
        let params = {
          grant_type: 'authorization_code',
          code: authCode,
          client_id: this.cognitoClientId,
          redirect_uri: this.domainURL,
        };

        localStorage.removeItem('authToken');
        localStorage.setItem('client_id', this.cognitoClientId);
        localStorage.setItem('cognito_region', this.cognitoRegion);
        this.getToken(hostURL, params, (response: any) => {
          if (response) {
            localStorage.setItem('authToken', response.access_token);
            this.router.navigate(['/conversation-insights']);
          }
        });
      } catch (exception) {}
    }
  }
  getToken(hostURL: any, paramsObj: any, success: any) {
    let params = new HttpParams();
    params = params.append('grant_type', paramsObj.grant_type);
    params = params.append('code', paramsObj.code);
    params = params.append('client_id', paramsObj.client_id);
    params = params.append('redirect_uri', paramsObj.redirect_uri);

    this.http.post(hostURL, params, {}).subscribe((response: any) => {
      if (response) {
        success(response);
      }
    });
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('authToken');
  }

  logout() {
    localStorage.removeItem('authToken');
    this.router.navigate(['/login']);
  }
}
