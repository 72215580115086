import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { ChatBoxComponent } from '../chat-box/chat-box.component';
import { HistoryComponent } from '../history/history.component';
import { GlossaryComponent } from '../glossary/glossary.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-right-sidebar',
  standalone: true,
  imports: [CommonModule, ChatBoxComponent, HistoryComponent, GlossaryComponent, MatTooltipModule],
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss']
})
export class RightSidebarComponent {
  @Output() valueChange = new EventEmitter<boolean>();

  isExpandBoxVisible: boolean = false;
  componentSelector: string | null = null;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() { }

  toggleExpandBox(event: MouseEvent) {
    this.isExpandBoxVisible = !this.isExpandBoxVisible;
    this.valueChange.emit(this.isExpandBoxVisible);
    this.cdr.detectChanges();
  }

  changeComponentSelector(event: MouseEvent, tabName: string) {
    if (this.componentSelector === tabName) {
      this.componentSelector = null;
      this.isExpandBoxVisible = false;
    } else {
      this.componentSelector = tabName;
      this.isExpandBoxVisible = true;
    }
    this.valueChange.emit(this.isExpandBoxVisible);
    this.cdr.detectChanges();
  }
}