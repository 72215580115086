import { DataItem } from "./glossary.component";

export const DATA_ITEMS: DataItem[] = [
  {
    title: 'Summary',
    message: 'A short, easy-to-read summarization of the full conversation transcript.',
  },
  {
    title: 'Primary Topic',
    message: 'The main topic of the conversation.'
  }, {
    title: 'Customer Intent',
    message: 'The action that the customer wants to take, or information they are seeking.'
  },
  {
    title: 'Root Cause',
    message: 'The reason or underlying issue that led the customer to initiate the conversation.'
  },
  {
    title: 'Customer Type',
    values: ['Regular-Customer and Travel-Agency']
  },
  {
    title: 'Journey Moment',
    message: 'At what point in their journey are the customers calling.',
    values: ['Pre-Travel', 'Pre-Flight', 'At-Airport', 'In-Flight', 'At-Destination', 'Post-Flight', 'Post-Travel']
  },
  {
    title: 'Sentiment',
    message: 'Customer sentiment at the end of the conversation.',
    values: ['Positive', 'Neutral', 'and Negative']
  },
  {
    title: 'Issue Resolution',
    message: 'Status of the customer issue at the end of the conversation.',
    values: ['Nothing-To-Resolve', 'Transferred', 'Resolved', 'Cannot-Resolve', 'or Others.']
  },
  {
    title: 'Call Duration',
    message: 'Length of the conversation.'
  },
];
