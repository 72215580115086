<div class="container-class">
  <div class="heading-class">
    <span>Glossary</span>
  </div>
  <div class="details-class">
    <div class="box-container" *ngFor="let item of dataItems">
      <div class="notification-title">
        <span>{{ item.title }}</span>
      </div>
      <div class="message" *ngIf="item.message && item.message.length">
        <span>{{ item.message }}</span>
      </div>
      <div class="value" *ngIf="item.values && item.values.length">
        <span>Values: </span>
        <span class="values-message">{{ item.values.join(', ') }}</span>
      </div>
    </div>
  </div>
</div>