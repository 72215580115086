<div class="login">
  <div class="loginLeft">
    <div class="imageArt">
      <img src="../../assets/artwork.svg" />
    </div>
    <div class="UpperP">Personalized Care at Scale</div>

    <div class="LowerP">
      RozieAI helps organizations deliver best-in-class customer service
      experiences.
    </div>
  </div>
  <div class="loginRight">
    <div class="loginButtons">
      <div class="care"><img src="../../assets/logo.svg" /></div>
      <div class="loginBtn" (click)="login()" id="loginWithAD">
        <button>Login with Active Directory</button>
      </div>
    </div>

    <div class="footer">
      <span class="powered-by">Powered By</span
      ><img src="../../assets/rozie-footer.svg" />
    </div>
  </div>
</div>
