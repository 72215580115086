import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  // private jsonData!: any[];

  // setData(data: any[]): void {
  //   this.jsonData = data;
  // }

  // getData(): any[] {
  //   return this.jsonData;
  // }

  public apiData: any;
  public apiTotalRow: any;

  constructor() { }

  setApiData(data: any): void {
    this.apiData = data;
  }

  getApiData(): any {
    return this.apiData;
  }

  setapiTotalRow(totalRow: any): void {
    this.apiTotalRow = totalRow;
  }

  getapiTotalRow(): any {
    return this.apiTotalRow;
  }
}
