<div class="table-container">
    <div class="mat-elevation-z8">
        <!-- Data view table actions -->
        <div class="search-wrapper">
            <!-- Table search bar -->
            <div class="search-bar-container">
                <img src="../../../../assets/icons/search.svg" alt="search">
                <input matInput (keyup)="applyFilter($event)" placeholder="Search here..." #input>
            </div>

            <!-- Table file export -->
            <button class="btn view-download-btn" (click)="exportPlot()" matTooltip="Download File">
                <img src="../../../assets/icons/export_icon_grey.svg" alt="visibility" />
            </button>
        </div>

        <!-- Data view table -->
        <div class="table-wrapper" *ngIf="!commonservice.loaderFlag">
            <div class="main-view-conatiner">
                <section class="main-container">
                    <table mat-table [dataSource]="commonservice.filterdDataSource" matSort class="table-class">
                        <ng-container *ngFor="let item of commonservice.headers">
                            <ng-container [matColumnDef]="item.name" class="other-row">
                                <th class="table-heading" mat-header-cell *matHeaderCellDef mat-sort-header>
                                    {{ item.name }}
                                </th>
                                <td mat-cell *matCellDef="let row" class="table-data">
                                    <div class="cell-content-wrapper">
                                        <!-- Handle regular columns with short text -->
                                        <div
                                            *ngIf="!(row[item.key]?.toString()?.length > 25) && item.key != 'CustomerSentimentAtStart' && item.key != 'CustomerSentimentAtEnd' && item.key != 'Date' && item.key != 'Agent_ConnectedToAgentTimestamp' && item.key != 'Agent_AgentInteractionDuration'">
                                            {{ row[item.key]?.toString() || "-" }}
                                        </div>

                                        <!-- Handle long text with tooltip -->
                                        <div matTooltip="{{ row[item.key] }}"
                                            *ngIf="row[item.key]?.toString()?.length > 25 && item.key != 'Date'">
                                            {{ row[item.key]?.toString() | slice : 0 : 25 }}{{
                                            row[item.key]?.toString().length > 25 ? "..." : "" }}
                                        </div>

                                        <!-- Handle Customer Sentiment Start -->
                                        <div [ngClass]="{
                                            'auto-answer-positive': row[item.key]?.toString().trim().toLowerCase() === 'positive',
                                            'auto-answer-negative': row[item.key]?.toString().trim().toLowerCase() === 'negative',
                                            'auto-answer-neutral': row[item.key]?.toString().trim().toLowerCase() === 'neutral'
                                        }"
                                            *ngIf="!(row[item.key]?.toString().trim().length > 30) && item.key === 'CustomerSentimentAtStart'">
                                            {{ row[item.key] ? (row[item.key]?.toString().trim().toLowerCase() ===
                                            'positive' ? 'Positive' : (row[item.key]?.toString().trim().toLowerCase()
                                            === 'negative' ? 'Negative' :
                                            (row[item.key]?.toString().trim().toLowerCase() === 'neutral' ? 'Neutral' :
                                            '-'))) : '-' }}
                                        </div>

                                        <!-- Handle Customer Sentiment End -->
                                        <div [ngClass]="{
                                            'auto-answer-positive': row[item.key]?.toString().trim().toLowerCase() === 'positive',
                                            'auto-answer-negative': row[item.key]?.toString().trim().toLowerCase() === 'negative',
                                            'auto-answer-neutral': row[item.key]?.toString().trim().toLowerCase() === 'neutral'
                                        }"
                                            *ngIf="!(row[item.key]?.toString().trim().length > 30) && item.key === 'CustomerSentimentAtEnd'">
                                            {{ row[item.key] ? (row[item.key]?.toString().trim().toLowerCase() ===
                                            'positive' ? 'Positive' : (row[item.key]?.toString().trim().toLowerCase()
                                            === 'negative' ? 'Negative' :
                                            (row[item.key]?.toString().trim().toLowerCase() === 'neutral' ? 'Neutral' :
                                            '-'))) : '-' }}
                                        </div>

                                        <!-- Handle Date column with formatting -->
                                        <div *ngIf="item.key === 'Date'">
                                            {{ formatDateString(row[item.key]?.toString()) }}
                                        </div>

                                        <!-- Handle Agent_ConnectedToAgentTimestamp column with formatting -->
                                        <div *ngIf="item.key === 'Agent_ConnectedToAgentTimestamp'">
                                            {{ row[item.key] && row[item.key] !== 'NA' ?
                                            formatDateString(row[item.key]?.toString()) : 'NA' }}
                                        </div>

                                        <!-- Handle Agent_AgentInteractionDuration column with formatting -->
                                        <div *ngIf="item.key === 'Agent_AgentInteractionDuration'">
                                            {{ row[item.key] && row[item.key] !== 'NA' ?
                                            formatTimestampForAgentDuration(row[item.key]?.toString()) : 'NA' }}
                                        </div>

                                        <!-- Copy to Clipboard button -->
                                        <button mat-icon-button class="copy-button" matTooltip="Copy to Clipboard"
                                            (click)="copyToClipboard(getFormattedValue(row, item))">
                                            <mat-icon class="content-copy-icon">content_copy</mat-icon>
                                        </button>
                                    </div>
                                </td>
                            </ng-container>
                        </ng-container>
                        <tr mat-header-row class="table-heading"
                            *matHeaderRowDef="commonservice.displayedColumns; sticky: true"></tr>
                        <tr class="table-row" mat-row *matRowDef="let row; columns: commonservice.displayedColumns">
                        </tr>
                    </table>
                    <div *ngIf="commonservice.dataSource.data.length === 0"
                        [ngStyle]="{'display': (commonservice.dataSource.data.length === 0) ? 'block' : 'none'}">
                        <tr class="mat-row-data">
                            <td class="no-data" [attr.colspan]="commonservice.displayedColumns.length">
                                {{labels.no_data_found}}
                            </td>
                        </tr>
                    </div>
                </section>
                <!-- Pagination -->
                <mat-paginator class="table-paginator" [length]="commonservice.totalSize"
                    [pageSize]="commonservice.perPageItem" aria-label="Select page of users"
                    class="hidden-paginator"></mat-paginator>
                <div class="main-pagination">
                    <div class="custom-pagination-container">
                        <div class="pagination">
                            <button *ngIf="commonservice.historyPageNumber > 1"
                                (click)="onPageChange(commonservice.historyPageNumber - 1)"><span class="arrow1"><img
                                        src="../../../../assets/icons/prev_arrow.svg" alt></span></button>

                            <ng-container *ngFor="let pageNumber of getPaginationRange()">
                                <ng-container *ngIf="pageNumber !== '...'">
                                    <button [class.active]="pageNumber === commonservice.historyPageNumber"
                                        (click)="onPageChange(convertToNumber(pageNumber))">
                                        {{ pageNumber }}
                                    </button>
                                </ng-container>
                                <span class="elispsis" *ngIf="pageNumber === '...'">...</span>
                            </ng-container>

                            <button *ngIf="commonservice.historyPageNumber < commonservice.visiblePageBlock"
                                (click)="onPageChange(commonservice.historyPageNumber + 1)">
                                <span class="arrow1"><img src="../../../../assets/icons/next_arrow.svg" alt /></span>
                            </button>
                        </div>
                    </div>
                    <!-- Per page selector -->
                    <div class="page-selection" *ngIf="commonservice.dataSource.data.length !== 0">
                        <mat-select [(value)]="commonservice.perPageItem" class="selector"
                            (valueChange)="selectPage($event)">
                            <mat-option value="10">10</mat-option>
                            <mat-option value="20">20</mat-option>
                            <mat-option value="30">30</mat-option>
                            <mat-option value="40">40</mat-option>
                            <mat-option value="50">50</mat-option>
                        </mat-select>
                        <div class="page-range"
                            *ngIf="commonservice.historyPageNumber && commonservice.historyPageNumber >= 1">
                            {{labels.result}} {{ commonservice.getPageRange() }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Table loader -->
        <div *ngIf="commonservice.loaderFlag" class="main-view-conatiner-loader">
            <app-skeleton-loader [width]="'100%'" [height]="'100%'"></app-skeleton-loader>
        </div>
    </div>
</div>