export const navbarData = [
    // {
    //     routeLink: 'dashboard',
    //     icon: '../../../../assets/icons/dashboard_customize.svg',
    //     label: 'Executive Overview'
    // },
    // {
    //     routeLink: 'integration',
    //     icon: '../../../../assets/icons/integration.svg',
    //     label: 'Self Service'
    // },
    // {
    //     routeLink: 'responses',
    //     icon: '../../../../assets/icons/responses.svg',
    //     label: 'Content Analysis'
    // },
    {
        routeLink: 'conversation-insights',
        icon: '../../../../assets/icons/bar_chart_icon.svg',
        label: 'Conversation Insights'
    }
];