<div class="dashboard-container"
  [ngStyle]="{'grid-template-columns': isChatBoxVisible ? 'calc(100% - 374px) 374px' : 'calc(100% - 54px) 54px'}">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="Data Explorer">
      <app-data-explorer [chatboxVisibilityFlag]="isChatBoxVisible"></app-data-explorer>
    </mat-tab>
    <!-- <mat-tab label="My Report" style="display: none;">
                <app-my-report></app-my-report>
        </mat-tab> -->
  </mat-tab-group>
  <div class="chatbox-main-container">
    <app-right-sidebar (valueChange)="onValueChange($event)"></app-right-sidebar>
  </div>
</div>