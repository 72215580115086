import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileConverterService {
  constructor(private http: HttpClient) {}

  convertFile(filePath: string): Observable<any[]> {
    const fileExtension = filePath.split('.').pop()?.toLowerCase();
    if (!fileExtension) {
      return throwError('File extension not supported.');
    }

    if (fileExtension === 'csv') {
      return this.convertCsv(filePath);
    } else if (fileExtension === 'xlsx') {
      return this.convertXlsx(filePath);
    } else {
      return throwError('File format not supported.');
    }
  }

  private convertCsv(filePath: string): Observable<any[]> {
    return this.http.get(filePath, { responseType: 'text' }).pipe(
      map((data) => this.csvToJson(data)),
      catchError((error) => {
        return throwError('Error converting CSV file.');
      })
    );
  }

  private csvToJson(csv: string): any[] {
    return [];
  }

  private convertXlsx(filePath: string): Observable<any[]> {
    return this.http.get(filePath, { responseType: 'arraybuffer' }).pipe(
      map((data) => this.xlsxToJson(data)),
      catchError((error) => {
        return throwError('Error converting XLSX file.');
      })
    );
  }

  private xlsxToJson(data: ArrayBuffer): any[] {
    const workbook = XLSX.read(new Uint8Array(data), { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
  
    const jsonData: any[][] = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false });
  
    if (!jsonData || jsonData.length === 0) {
      return [];
    }
  
    const headers: string[] = jsonData[0].map((header: string) => header.toString());
  
    const rows = jsonData.slice(1);
  
    return rows.map((row: any[]) => {
      const obj: any = {};
      headers.forEach((header, index) => {
        if (sheet["!ref"]) {
          const cell = sheet[XLSX.utils.encode_cell({ r: index + 1, c: headers.indexOf(header) })];
          if (cell && cell.t === 'd') {
            obj[header] = this.formatDate(cell.v);
          } else {
            obj[header] = row[index];
          }
        } else {
          obj[header] = row[index];
        }
      });
      return obj;
    });
  }
  
  private formatDate(dateValue: number): string {
    const date = new Date(Math.round((dateValue - 25569) * 86400 * 1000));
    return date.toISOString().replace('T', ' ').substring(0, 19);
  }  
}
