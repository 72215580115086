import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, catchError, map } from 'rxjs';
import { CognitoService } from './cognito.service';
import pako from 'pako';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  rowValue: any;
  displayedColumns: any
  tableColumnSearchKey: any
  dataSource: any = new MatTableDataSource<any>();
  filterdDataSource: any = new MatTableDataSource<any>();
  headers: any[] = [];
  loaderFlag: Boolean = true;

  perPageItem: string = "10"
  totalSize: number = 1
  visiblePageBlock: number = 0
  historyPageNumber: any = 1
  selectedAdminView: any = ''

  exportCSVDataResponse: any;
  exportCSVDataResponseStatus: any;

  private baseUrl = process.env['API_DOMAIN_NAME'];

  constructor(private https: HttpClient, private cognitoService: CognitoService) { }

  getHeaderNames(headers: any): string[] {
    return headers.map((header: any) => header.name);
  }

  getChartJSONData(): Observable<any> {
    return this.https.get<any>('assets/data/may7final.json');
  }

  private getAuthHeaders(): HttpHeaders {
    const accessToken = localStorage.getItem('authToken');
    if (!accessToken) {
      throw new Error('Access token not available.');
    }
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': accessToken
    });
  }

  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error);
    throw error;
  }

  private postRequest(endpoint: string, body: any, params?: HttpParams): Observable<any> {
    return this.https.post<any>(`${this.baseUrl}/${endpoint}`, body, { headers: this.getAuthHeaders(), params })
      .pipe(catchError(this.handleError));
  }

  fetchOverview(body: any): Observable<any> {
    return this.postRequest('api/calculate-overview', body);
  }

  fetchTableValues(body: any, pageSize: number, pageNumber: number): Observable<any> {
    const params = new HttpParams()
      .set('page_size', pageSize.toString())
      .set('page_number', pageNumber.toString());
    return this.postRequest('api/get-raw-table', body, params);
  }

  fetchSentimentSankeyDataWithDate(body: any): Observable<any> {
    return this.postRequest('get-data/customers-sentiment-shift-over-time', body);
  }

  fetchPrimaryTopicSankeyDataWithDate(body: any): Observable<any> {
    return this.postRequest('get-data/primary-topic-and-intent-over-time', body);
  }

  fetchDataFilters(body: any): Observable<any> {
    return this.postRequest('api/all-filters', body);
  }

  fetchPrimaryTopicPercentageDataWithDate(body: any): Observable<any> {
    return this.postRequest('get-data/primary-topics-over-time', body);
  }

  fetchCustomerIntentPercentageDataWithDate(body: any): Observable<any> {
    return this.postRequest('get-data/customer-intent-over-time', body);
  }

  fetchPrimaryTopicFrequencyBarDataWithDate(body: any): Observable<any> {
    return this.postRequest('get-data/primary-topics-distribution', body);
  }

  fetchResolutionBarDataWithDate(body: any): Observable<any> {
    return this.postRequest('get-data/resolution-distribution', body);
  }

  getPageRange(): string {
    if (
      isNaN(this.historyPageNumber) ||
      this.historyPageNumber < 1
    ) {
      return '';
    }
    if (parseInt(this.perPageItem) > this.totalSize) {
      const startIndex = 1
      const endIndex = Math.min(startIndex + parseInt(this.perPageItem) - 1, this.totalSize);
      return `${startIndex} - ${endIndex} of ${this.totalSize}`;
    }
    else {
      const startIndex =
        (this.historyPageNumber - 1) * parseInt(this.perPageItem) + 1;
      const endIndex = Math.min(startIndex + parseInt(this.perPageItem) - 1, this.totalSize);
      return `${startIndex} - ${endIndex} of ${this.totalSize}`;
    }
  }

  exportDataToCSV(body: any): Observable<{ url: string }> {
    const endpoint = 'api/get-raw-data-csv';
    const accessToken = localStorage.getItem('authToken');
  
    if (!accessToken) {
      throw new Error('Access token not available.');
    }
  
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': accessToken
    });
  
    return this.https.post<{ url: string }>(`${this.baseUrl}/${endpoint}`, body, { headers: headers });
  }

  exportDataToCSVFile(body: any): Observable<{ url: string }> {
    const endpoint = 'api/check_file_availability';
    const accessToken = localStorage.getItem('authToken');
  
    if (!accessToken) {
      throw new Error('Access token not available.');
    }
  
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': accessToken
    });
  
    return this.https.post<{ url: string }>(`${this.baseUrl}/${endpoint}`, body, { headers: headers });
  }
}
