<div class="container-class">
    <div class="heading-class">
        <span>Data Navigator</span>
    </div>
    <div class="details-class">
        <div class="box-container">
            <div class="notification-title">
                <img src="../../../../assets/icons/loading-icon.svg" alt="loading">
                <span>Coming soon!</span>
            </div>
            <div class="message">
                <span>Stay tuned.</span>
            </div>
        </div>
    </div>
</div>