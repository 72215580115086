import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChatBoxComponent } from './chat-box/chat-box.component';
import { MyReportComponent } from './my-report/my-report.component';
import { DataExplorerComponent } from './data-explorer/data-explorer.component';
import { CommonService } from '../../services/common.service';
import { Observable } from 'rxjs';
import { RightSidebarComponent } from './right-sidebar/right-sidebar.component';
@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule, MatTabsModule, MatMenuModule, MatIconModule, HighchartsChartModule, ChatBoxComponent, MyReportComponent, DataExplorerComponent, RightSidebarComponent],
  providers: [CommonService],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {

  isChatBoxVisible: boolean = false;

  constructor(
    public commonService: CommonService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() { }

  onValueChange(newValue: any) {
    this.isChatBoxVisible = newValue;
  }
}
