import { Component } from '@angular/core';
import { DATA_ITEMS } from './glossary_details';
import { CommonModule } from '@angular/common';

export interface DataItem {
  title: string;
  message?: string;
  values?: string[];
}

@Component({
  selector: 'app-glossary',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './glossary.component.html',
  styleUrl: './glossary.component.scss'
})
export class GlossaryComponent {
  dataItems: DataItem[] = [];

  ngOnInit() {
    this.dataItems = DATA_ITEMS;
  }
}
