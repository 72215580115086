import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoService } from '../../services/cognito.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {

  constructor( private router: Router, private cognitoService: CognitoService) {}

  ngOnInit(): void {
  }

  clickLogout(){
    this.router.navigate(['login']);
    localStorage.clear();
    this.cognitoService.deleteCookies();
    this.cognitoService.clearCookies();
    localStorage.removeItem('authToken');
  }
}