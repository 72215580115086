import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { navbarData } from './navbar-tab';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, RouterLinkActive, MatSidenavModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  activeTab: string = 'conversation-insights';

  collapsed = true;
  navData = navbarData;

  @Output() collapsedChanged = new EventEmitter<boolean>();

  onExpand(){
    this.collapsed = false;
    this.collapsedChanged.emit(this.collapsed);
  }

  onCollapse(){
    this.collapsed = true;
    this.collapsedChanged.emit(this.collapsed);
  }
}
