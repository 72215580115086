<div class="data-explorer-container">
  <div class="result-container">
    <!-- Breadcrumbs -->
    <div class="top-header-wrapper" style="display: none;">
      <label class="top-header" (click)="popFromPathStack()">
        <span class="top-header-first-element">{{labels.home}}</span>
        <ng-container *ngIf="pathStack.length > 1" class="path-navigation">
          <img src="../../../../assets/icons/arrow_forward.svg" alt="next">
          <span>{{ pathStack[pathStack.length - 1] }}</span>
        </ng-container>
      </label>
    </div>

    <!-- Data-explorer actions -->
    <div class="duration-container">
      <!-- Toggle view modes -->
      <div class="toggle-container">
        <button [ngClass]="{'active': !tableViewFlag}" (click)="showChartView()">
          <img src="../../../../assets/icons/chart_view.svg" *ngIf="!tableViewFlag" alt="Chart View">
          <img src="../../../../assets/icons/chart_view_grey.svg" *ngIf="tableViewFlag" alt="Chart View">
          <span>{{labels.chart_view}}</span>
        </button>
        <button [ngClass]="{'active': tableViewFlag}" (click)="showDataView()">
          <img src="../../../../assets/icons/data_view.svg" *ngIf="!tableViewFlag" alt="Data View">
          <img src="../../../../assets/icons/data_view_white.svg" *ngIf="tableViewFlag" alt="Data View">
          <span>{{labels.data_view}}</span>
        </button>
      </div>
      <!-- Daterange picker -->
      <div class="datepicker-class">
        <div class="row mt-3 mb-3">
          <div class="col-4" style="display: flex; flex-direction: row;">
            <input class="rangepicker-input" type="text" id="rangeCalendarAutoLeft" class="form-control" style="box-shadow: none; 
                    text-overflow: ellipsis; 
                    overflow: hidden; 
                    white-space: nowrap; 
                    

                    color: var(--background-variant-6475258, #475258);

                    font-family: Lexend;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: -0.14px;
                    
                    border-radius: 4px;
                    border: 1px solid var(--background-variant-3-bfccd-9, #BFCCD9);
                    background: var(--background-primary-ffffff, #FFF);

                    display: flex;
                    
                    padding: 8px 10px !important;
                    align-items: center;
                    gap: 8px;
                    align-self: stretch;
                    width: 198px;
                    margin-right: -30px;
                    cursor: pointer;
                    " readonly ngxDaterangepickerBootstrap [(ngModel)]="selectedRangeCalendarAutoLeft"
              [drops]="dropsDown" [opens]="opensLeft" [locale]="locale" [ranges]="ranges" [showCustomRangeLabel]="true"
              [showCancel]="true" [linkedCalendars]="true" [alwaysShowCalendars]="true" [showRangeLabelOnInput]="false"
              [keepCalendarOpeningWithRange]="true" [closeOnAutoApply]="true" [autoApply]="true"
              [isTooltipDate]="isTooltipDate" [isCustomDate]="isCustomDates" [isInvalidDate]="isInvalidDate"
              (datesUpdated)="datesUpdatedRange($event)"
              (ngModelChange)="datesUpdatedRange($event); loadFetchFunctions()"
              aria-describedby="rangeCalendarAutoLeftDesc" />
            <img src="../../../../assets/icons/dropicon_frame.svg" alt="">
          </div>
        </div>
      </div>
    </div>

    <!-- Filter section -->
    <div class="overview-container conversation-box">
      <div class="overview-header">
        <h3 class="left-section">
          <span>{{labels.data_filters}}</span>
        </h3>
        <div class="right-section">
          <div class="filter-actions" *ngIf="!commonservice.loaderFlag">
            <button (click)="applyFilters(); makeLoaderTrue($event)" [ngClass]="{'disabled': !filterChanges}"
              [disabled]="!filterChanges"><span>{{labels.apply_filter}}</span></button>
          </div>
          <div class="hide-filter-box" matTooltip="Show Filters">
            <img src="../../../../assets/icons/unfold_more.svg" (click)="filterBoxAction($event)" alt="expand">
          </div>
        </div>
      </div>
      <div class="overview-content" *ngIf="isContentVisible" (click)="onContentClick($event)">
        <div class="add-btn" *ngIf="isContentVisible" (click)="toggleFilterOptions($event)"
          [class.selected]="openFilterOptions">
          <span class="btn-text">+</span>
          <div *ngIf="openFilterOptions" class="filter-options-container">
            <ng-container *ngFor="let filterGroup of filterGroups; let i = index">
              <div class="filter-option" (click)="toggleGroupOptions(i, $event)">
                <div>{{filterGroup?.showName}}</div><img src="../../../../assets/icons/arrow_forward_grey.svg">
                <div class="group-options" *ngIf="activeGroupIndex === i" (click)="stopPropagation($event)">
                  <div class="option-header">Select {{filterGroup?.showName}}</div>
                  <div class="option-search-container">
                    <input type="text" [(ngModel)]="searchQuery[i]" placeholder="Search"
                      (click)="stopPropagation($event)" class="custom-input">
                  </div>

                  <div class="option" *ngIf="!searchQuery[i]">
                    <mat-checkbox (change)="selectAll($event, i)" [checked]="filterGroup?.filters?.allSelected"
                      color="primary">{{labels.all}} </mat-checkbox>
                  </div>

                  <div class="option"
                    *ngFor="let option of searchFilter(filterGroup?.filters?.options, searchQuery[i])">
                    <mat-checkbox (change)="toggleSelection(option, $event, i)" [checked]="option.isSelected"
                      color="primary">
                      {{option.name}}
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <ng-container *ngFor="let filterGroup of filterGroups; let i = index">
          <div *ngIf="shouldShowGroup(filterGroup)" class="filter-box" (click)="toggleChipGroupOptions(i, $event)"
            [ngClass]="{'filter-box-selected': activeGroupIndex1 === i}">
            <div class="filter-header">{{filterGroup.showName}}: </div>
            <div class="filter-content">
              <span *ngIf="filterGroup.filters.allSelected">All</span>
              <span *ngIf="!filterGroup.filters.allSelected && getSelectedCount(filterGroup.filters.options) === 1">
                {{getFirstSelectedOption(filterGroup.filters.options)}}
              </span>
              <div *ngIf="!filterGroup.filters.allSelected && getSelectedCount(filterGroup.filters.options) > 1"
                class="filter-content">
                {{getFirstSelectedOption(filterGroup.filters.options)}} +
                <div class="extra-filters">{{getSelectedCount(filterGroup.filters.options) - 1}}</div>
              </div>
            </div>
            <div class="remove-filter" (click)="removeAllSelected(i)">
              <img src="../../../../assets/icons/close.svg">
            </div>
            <div class="chip-group-options" *ngIf="chipActiveGroupIndex === i" (click)="stopPropagation($event)">

              <div class="option-header">{{labels.select}} {{filterGroup.showName}}</div>
              <div class="option-search-container">
                <input type="text" [(ngModel)]="searchQuery[i]" placeholder="Search" (click)="stopPropagation($event)"
                  class="custom-input">
              </div>
              <div class="chip-group-class">
                <div class="option" *ngIf="!searchQuery[i]">
                  <mat-checkbox (change)="selectAllTemp($event, i)" [checked]="filterGroup.filters.tempAllSelected"
                    color="primary">All</mat-checkbox>
                </div>
                <div class="option" *ngFor="let option of searchFilter(filterGroup.filters.options, searchQuery[i])">
                  <mat-checkbox (change)="toggleTempSelection(option, $event, i)" [checked]="option.tempIsSelected"
                    color="primary">
                    {{option.name}}
                  </mat-checkbox>
                </div>
              </div>
              <div class="apply-button-container">
                <button (click)="applyFilterChanges(i); stopPropagation($event)" [disabled]="!isSelectButtonEnabled[i]"
                  [ngClass]="{'disabled': !isSelectButtonEnabled[i]}">
                  <span>{{labels.select}}</span>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- Conversation overview section -->
    <div class="overview-container conversation-box" *ngIf="getVisibleChartCount() > 1 &&  !tableViewFlag">
      <div class="overview-header">
        <h3 class="left-section">
          <span>{{ overviewSectionTitle }}</span>
        </h3>
        <div class="right-section">
          <div class="right-section-btn-container">
          </div>
        </div>
      </div>
      <div class="overview-content overview-details-container">
        <div class="content-item">
          <label>{{labels.total_conversations}}</label>
          <div class="data" *ngIf="!commonservice.loaderFlag">{{
            conversationOverview?.['Total Conversations'] }}</div>
          <app-skeleton-loader *ngIf="commonservice.loaderFlag" [width]="'80px'"
            [height]="'20px'"></app-skeleton-loader>
        </div>
        <div class="content-item">
          <label>{{labels.primary_topics}}</label>
          <div class="data" *ngIf="!commonservice.loaderFlag">{{
            conversationOverview?.['Primary Topics'] }}</div>
          <app-skeleton-loader *ngIf="commonservice.loaderFlag" [width]="'80px'"
            [height]="'20px'"></app-skeleton-loader>
        </div>
        <!-- <div class="content-item">
          <label *ngIf="!commonservice.loaderFlag">{{labels.customer_intents}}</label>
          <div class="data" *ngIf="!commonservice.loaderFlag">{{
                        conversationOverview['Customer Intents'] }}</div>
          <app-skeleton-loader *ngIf="commonservice.loaderFlag" [width]="'61px'" [height]="'9px'"></app-skeleton-loader>
        </div> -->
        <div class="content-item">
          <label>{{labels.average_duration}}</label>
          <div class="data" *ngIf="!commonservice.loaderFlag">{{
            conversationOverview?.['Average Duration']}}</div>
          <app-skeleton-loader *ngIf="commonservice.loaderFlag" [width]="'80px'"
            [height]="'20px'"></app-skeleton-loader>
        </div>
        <div class="content-item">
          <label>{{labels.issue_resolution_rate}}</label>
          <div class="data" *ngIf="!commonservice.loaderFlag">{{
            conversationOverview?.['Issue Resolution Rate'] |
            number:'1.0-2' }}%</div>
          <app-skeleton-loader *ngIf="commonservice.loaderFlag" [width]="'80px'"
            [height]="'20px'"></app-skeleton-loader>
        </div>
        <div class="content-item">
          <label>{{labels.overall_sentiment}}</label>
          <div class="data" *ngIf="!commonservice.loaderFlag"
            [ngClass]="getSentimentClass(conversationOverview?.['Overall Sentiment'])">
            {{ conversationOverview?.['Overall Sentiment'] || 'N/A' }}
          </div>
          <app-skeleton-loader *ngIf="commonservice.loaderFlag" [width]="'80px'"
            [height]="'20px'"></app-skeleton-loader>
        </div>
      </div>
    </div>

    <!-- Render data view mode -->
    <div class="raw-table-view" *ngIf="tableViewFlag">
      <app-tabular-view [data]="tableViewBody"></app-tabular-view>
    </div>

    <!-- Go back section -->
    <div class="go_back_class" *ngIf="!tableViewFlag && (getVisibleChartCount() === 1)">
      <span (click)="popFromPathStack()">
        <button>
          <img src="../../../../assets/icons/go_back.svg" alt="Go Back">
        </button>
        <span>{{labels.go_back_check}}</span>
      </span>
    </div>

    <!-- Highcharts Plots container  -->
    <div class="chart-container" *ngIf="!tableViewFlag"
      [ngClass]="{'custom-chart-container': getVisibleChartCount() === 1}">
      <!-- Chart (0,0)    Title: Primary topic over time
                                Type: Percentage
                                Data: Percentage of primary topic vs date -->
      <div class="overview-container" *ngIf="percentageAreaPrimaryTopicChartVisible" id="PrimaryTopicsTrendsOverTime"
        #divToDownload1>
        <div class="overview-header">
          <h3 class="left-section">
            <span *ngIf="!commonservice.loaderFlag">{{labels.primary_topic_over_time}}</span>
            <div class="left-section-loader">
              <app-skeleton-loader *ngIf="commonservice.loaderFlag" [width]="'40px'"
                [height]="'24px'"></app-skeleton-loader>
              <app-skeleton-loader *ngIf="commonservice.loaderFlag" [width]="'152px'"
                [height]="'24px'"></app-skeleton-loader>
            </div>
          </h3>
          <div class="right-section">
            <div class="right-section-btn-container">
              <button *ngIf="getVisibleChartCount() !== 1" class="btn view-download-btn" (click)="togglePercentageAreaPrimaryTopicChart(); 
                            pushToPathStack('Primary Topics Trends Over Time')">
                <img src="../../../assets/icons/view-icon-grey.svg" alt="visibility" matTooltip="View Chart" />
              </button>
              <button class="btn view-download-btn" (click)="downloadAsJPEG('PrimaryTopicsTrendsOverTime')">
                <img src="../../../assets/icons/export_icon_grey.svg" alt="visibility" matTooltip="Download Chart" />
              </button>
            </div>
          </div>
        </div>
        <div class="overview-content load-chart-container">
          <div class="charts-container">
            <ng-container *ngIf="!commonservice.loaderFlag">
              <highcharts-chart *ngIf="!noDataFoundFlag" [Highcharts]="Highcharts"
                [options]="percentageAreaPrimaryTopicChartOptions"
                [style]="getChartStyle(percentageAreaPrimaryTopicChartVisible)">
              </highcharts-chart>
              <div *ngIf="noDataFoundFlag" class="noDataFoundStyle"
                [style]="getChartStyle(percentageAreaPrimaryTopicChartVisible)">
                <p>{{labels.no_data_found}}</p>
              </div>
            </ng-container>

            <div *ngIf="commonservice.loaderFlag" class="chart-load main-view-conatiner-loader"
              [style]="getChartStyle(percentageAreaPrimaryTopicChartVisible)">
              <app-skeleton-loader [width]="'100%'" [height]="'100%'"></app-skeleton-loader>
            </div>

          </div>
        </div>
      </div>

      <!-- Chart (0,1)    Title: Customer intent over time
                                Type: Percentage
                                Data: Percentage of intent vs date-->
      <div class="overview-container" *ngIf="percentageAreaCustomerIntentChartVisible"
        id="CustomerIntentsTrendsOverTime" #divToDownload2>
        <div class="overview-header">
          <h3 class="left-section">
            <span *ngIf="!commonservice.loaderFlag">{{labels.customer_intent_over_time}}</span>
            <div class="left-section-loader">
              <app-skeleton-loader *ngIf="commonservice.loaderFlag" [width]="'40px'"
                [height]="'24px'"></app-skeleton-loader>
              <app-skeleton-loader *ngIf="commonservice.loaderFlag" [width]="'152px'"
                [height]="'24px'"></app-skeleton-loader>
            </div>
          </h3>
          <div class="right-section">
            <div class="right-section-btn-container">
              <button class="btn view-download-btn" matTooltip="View Chart" *ngIf="getVisibleChartCount() !== 1"
                (click)="toggleCutomerIntentPercentageAreaChart(); pushToPathStack('Customer Intents Trends Over Time')">
                <img src="../../../assets/icons/view-icon-grey.svg" alt="visibility" />
              </button>
              <button class="btn view-download-btn" (click)="downloadAsJPEG('CustomerIntentsTrendsOverTime')"
                matTooltip="Download Chart">
                <img src="../../../assets/icons/export_icon_grey.svg" alt="visibility" />
              </button>
            </div>
          </div>
        </div>
        <div class="overview-content load-chart-container">
          <div class="charts-container">

            <ng-container *ngIf="!commonservice.loaderFlag">
              <highcharts-chart *ngIf="!noDataFoundFlag" [Highcharts]="Highcharts"
                [options]="percentageAreaCustomerIntentChartOptions"
                [style]="getChartStyle(percentageAreaCustomerIntentChartVisible)">
              </highcharts-chart>
              <div *ngIf="noDataFoundFlag" class="noDataFoundStyle"
                [style]="getChartStyle(percentageAreaCustomerIntentChartVisible)">
                <p>{{labels.no_data_found}}</p>
              </div>
            </ng-container>

            <div *ngIf="commonservice.loaderFlag" class="chart-load main-view-conatiner-loader"
              [style]="getChartStyle(percentageAreaCustomerIntentChartVisible)">
              <app-skeleton-loader [width]="'100%'" [height]="'100%'"></app-skeleton-loader>
            </div>
          </div>
        </div>
      </div>

      <!-- chart (2, 0)   Title: Relationships between Primary Topic & Customer Intent
                                Type: Sankey
                                Data: Resolution vs frequency-->
      <div class="overview-container" *ngIf="sankeyDiagram1Visible"
        id="RelationshipsBetweenPrimaryTopicsCustomerIntents" #divToDownload5>
        <div class="overview-header">
          <h3 class="left-section">
            <span
              *ngIf="!commonservice.loaderFlag">{{labels.relationships_between_Primary_Topic_Customer_Intent}}</span>
            <div class="left-section-loader">
              <app-skeleton-loader *ngIf="commonservice.loaderFlag" [width]="'40px'"
                [height]="'24px'"></app-skeleton-loader>
              <app-skeleton-loader *ngIf="commonservice.loaderFlag" [width]="'152px'"
                [height]="'24px'"></app-skeleton-loader>
            </div>
          </h3>
          <div class="right-section">
            <div class="right-section-btn-container">
              <button *ngIf="getVisibleChartCount() !== 1" class="btn view-download-btn" matTooltip="View Chart"
                (click)="toggleSankeyDiagram1(); pushToPathStack('Relationships between Primary Topics & Customer Intents')">
                <img src="../../../assets/icons/view-icon-grey.svg" alt="visibility" />
              </button>
              <button class="btn view-download-btn"
                (click)="downloadAsJPEG('RelationshipsBetweenPrimaryTopicsCustomerIntents')"
                matTooltip="Download Chart">
                <img src="../../../assets/icons/export_icon_grey.svg" alt="visibility" />
              </button>
            </div>
          </div>
        </div>
        <div class="overview-content load-chart-container">
          <div class="charts-container">

            <ng-container *ngIf="!commonservice.loaderFlag">
              <highcharts-chart *ngIf="!noDataFoundFlag" [Highcharts]="Highcharts" [options]="sankeyDiagram1Options"
                [style]="getChartStyle(sankeyDiagram1Visible)">
              </highcharts-chart>
              <div *ngIf="noDataFoundFlag" class="noDataFoundStyle" [style]="getChartStyle(sankeyDiagram1Visible)">
                <p>{{labels.no_data_found}}</p>
              </div>
            </ng-container>
            <div *ngIf="commonservice.loaderFlag" class="chart-load main-view-conatiner-loader"
              [style]="getChartStyle(sankeyDiagram1Visible)">
              <app-skeleton-loader [width]="'100%'" [height]="'100%'"></app-skeleton-loader>
            </div>
          </div>
        </div>
      </div>

      <!-- chart (2, 1)   Title: Sentiment flow
                                Type: Sankey
                                Data: CustomerSentimentAtStart vs CustomerSentimentAtEnd-->
      <div class="overview-container" *ngIf="sankeyDiagram2Visible" id="ConversationSentimentFlow" #divToDownload6>
        <div class="overview-header">
          <h3 class="left-section">
            <span *ngIf="!commonservice.loaderFlag">{{labels.conversation_sentiment_flow}}</span>
            <div class="left-section-loader">
              <app-skeleton-loader *ngIf="commonservice.loaderFlag" [width]="'40px'"
                [height]="'24px'"></app-skeleton-loader>
              <app-skeleton-loader *ngIf="commonservice.loaderFlag" [width]="'152px'"
                [height]="'24px'"></app-skeleton-loader>
            </div>
          </h3>
          <div class="right-section">
            <div class="right-section-btn-container">
              <button *ngIf="getVisibleChartCount() !== 1" class="btn view-download-btn" (click)="toggleSankeyDiagram2(); 
                                pushToPathStack('Conversation Sentiment Flow')" matTooltip="View Chart">
                <img src="../../../assets/icons/view-icon-grey.svg" alt="visibility" />
              </button>
              <button class="btn view-download-btn" (click)="downloadAsJPEG('ConversationSentimentFlow')"
                matTooltip="Download Chart">
                <img src="../../../assets/icons/export_icon_grey.svg" alt="visibility" />
              </button>
            </div>
          </div>
        </div>
        <div class="overview-content load-chart-container">
          <div class="charts-container">
            <ng-container *ngIf="!commonservice.loaderFlag">
              <highcharts-chart *ngIf="!noDataFoundFlag" [Highcharts]="Highcharts" [options]="sankeyDiagram2Options"
                [style]="getChartStyle(sankeyDiagram2Visible)">
              </highcharts-chart>
              <div *ngIf="noDataFoundFlag" class="noDataFoundStyle" [style]="getChartStyle(sankeyDiagram2Visible)">
                <p>{{labels.no_data_found}}</p>
              </div>
            </ng-container>
            <div *ngIf="commonservice.loaderFlag" class="chart-load main-view-conatiner-loader"
              [style]="getChartStyle(sankeyDiagram2Visible)">
              <app-skeleton-loader [width]="'100%'" [height]="'100%'"></app-skeleton-loader>
            </div>
          </div>
        </div>
      </div>

      <!-- Chart (1,0)    Title: Primary topic distribution
                                Type: Bar
                                Data: Primary topic vs frequency-->
      <div class="overview-container" *ngIf="primaryTopicBarChartVisible" id="PrimaryTopicsTrendsOverTime"
        #divToDownload3>
        <div class="overview-header">
          <h3 class="left-section">
            <span *ngIf="!commonservice.loaderFlag">{{labels.primary_topic_distribution}}</span>
            <div class="left-section-loader">
              <app-skeleton-loader *ngIf="commonservice.loaderFlag" [width]="'40px'"
                [height]="'24px'"></app-skeleton-loader>
              <app-skeleton-loader *ngIf="commonservice.loaderFlag" [width]="'152px'"
                [height]="'24px'"></app-skeleton-loader>
            </div>
          </h3>
          <div class="right-section">
            <div class="right-section-btn-container">
              <button class="btn view-download-btn" *ngIf="getVisibleChartCount() !== 1" matTooltip="View Chart"
                (click)="togglePrimaryTopicBarChart(); pushToPathStack('Primary Topics Trends Over Time')">
                <img src="../../../assets/icons/view-icon-grey.svg" alt="visibility" />
              </button>
              <button class="btn view-download-btn" (click)="downloadAsJPEG('PrimaryTopicsTrendsOverTime')"
                matTooltip="Download Chart">
                <img src="../../../assets/icons/export_icon_grey.svg" alt="visibility" />
              </button>
            </div>
          </div>
        </div>
        <div class="overview-content load-chart-container">
          <div class="charts-container">

            <ng-container *ngIf="!commonservice.loaderFlag">
              <highcharts-chart *ngIf="!noDataFoundFlag" [Highcharts]="Highcharts"
                [options]="primaryTopicBarChartOptions" [style]="getChartStyle(primaryTopicBarChartVisible)">
              </highcharts-chart>
              <div *ngIf="noDataFoundFlag" class="noDataFoundStyle"
                [style]="getChartStyle(primaryTopicBarChartVisible)">
                <p>{{labels.no_data_found}}</p>
              </div>
            </ng-container>
            <div *ngIf="commonservice.loaderFlag" class="chart-load main-view-conatiner-loader"
              [style]="getChartStyle(primaryTopicBarChartVisible)">
              <app-skeleton-loader [width]="'100%'" [height]="'100%'"></app-skeleton-loader>
            </div>
          </div>
        </div>
      </div>

      <!-- chart (1,1)    Title: Distribution of resolution
                                                    Type: Bar
                                                    Data: Resolution vs frequency-->
      <div class="overview-container" *ngIf="resolutionBarChartVisible" id="ResolutionsOverview" #divToDownload4>
        <div class="overview-header">
          <h3 class="left-section">
            <span *ngIf="!commonservice.loaderFlag">{{labels.distribution_of_resolution}}</span>
            <div class="left-section-loader">
              <app-skeleton-loader *ngIf="commonservice.loaderFlag" [width]="'40px'"
                [height]="'24px'"></app-skeleton-loader>
              <app-skeleton-loader *ngIf="commonservice.loaderFlag" [width]="'152px'"
                [height]="'24px'"></app-skeleton-loader>
            </div>
          </h3>
          <div class="right-section">
            <div class="right-section-btn-container">
              <button class="btn view-download-btn" *ngIf="getVisibleChartCount() !== 1"
                (click)="toggleResolutionBarChart(); pushToPathStack('Resolutions Overview')" matTooltip="View Chart">
                <img src="../../../assets/icons/view-icon-grey.svg" alt="visibility" />
              </button>
              <button class="btn view-download-btn" (click)="downloadAsJPEG('ResolutionsOverview')"
                matTooltip="Download Chart">
                <img src="../../../assets/icons/export_icon_grey.svg" alt="visibility" />
              </button>
            </div>
          </div>
        </div>
        <div class="overview-content load-chart-container">
          <div class="charts-container">

            <ng-container *ngIf="!commonservice.loaderFlag">
              <highcharts-chart *ngIf="!noDataFoundFlag" [Highcharts]="Highcharts" [options]="resolutionBarChartOptions"
                [style]="getChartStyle(resolutionBarChartVisible)">
              </highcharts-chart>
              <div *ngIf="noDataFoundFlag" class="noDataFoundStyle" [style]="getChartStyle(resolutionBarChartVisible)">
                <p>{{labels.no_data_found}}</p>
              </div>
            </ng-container>
            <div *ngIf="commonservice.loaderFlag" class="chart-load main-view-conatiner-loader"
              [style]="getChartStyle(resolutionBarChartVisible)">
              <app-skeleton-loader [width]="'100%'" [height]="'100%'"></app-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Disclaimer section -->
    <div class="disclaimer">
      <span class="disclaimer-title">{{labels.disclaimer}}</span>
      <span class="disclaimer-message">{{labels.disclaimer_text}}</span>
    </div>
  </div>
</div>